import React from 'react';

const FilterRow = ({ levelName, chipsList, value, setValue }) => {
    return (
        <div className="c-filter-dropdown__level">
            <div className="-title">{levelName}</div>
            <div className="c-filter-dropdown__select">
                {chipsList && chipsList.length > 0 ? (
                    chipsList.map((item, index) => {
                        return (
                            <label
                                key={`${levelName}-${index}`}
                                htmlFor={`${levelName}-${item}-${index}`}
                                className="c-filter-dropdown__select-item"
                            >
                                <input
                                    type="radio"
                                    name={`radio-${levelName}`}
                                    id={`${levelName}-${item}-${index}`}
                                    checked={item === value}
                                    onClick={() => setValue(item)}
                                />
                                <span>{item}</span>
                            </label>
                        );
                    })
                ) : (
                    <span>No records found</span>
                )}
            </div>
        </div>
    );
};

export default FilterRow;
