import React, { useContext,useEffect, useState } from 'react';
import {
    // HashRouter as Router,
    Router,
    Redirect,
    Route,
    Switch
} from 'react-router-dom';
import GenwizardLoader from '@components/ui/loader/GenwizardLoader';
import { Routes } from '@constants/RoutingConstants';
import { ReleaseInfo } from '@containers/releaseinfo/ReleaseInfo';
import AppContext from '@context/app/AppContext';
import History from '@utils/History';

import Breadcrumbs from './breadcrumbs/Breadcrumbs';
import Footer from './layout/Footer';
import Header from './layout/Header';
import Navbar from './layout/Navbar';
import ManageRoute from './route/ManageRoute';
import OnboardRoute from './route/OnboardRoute';
import MicroView from '@containers/microFrontend/MicroView';
import useKeycloak from '@containers/keycloak/keycloak';
import { useRouteMatch } from 'react-router-dom';
import { getLazyQuery } from '@utils/Util';
import { GET_GLOBAL_CONFIG } from '@graphql/queries';
import SplashScreen from '@containers/manage/SplashScreen';
import Manage from '@containers/manage/Manage';


const RoutingModule = () => {

    const [globalLoad, setGlobalLoad] = useState(true);
    const PLATFORM_URL = process.env.REACT_APP_PLATFORM_URL;
    const childMainContentName = 'MainContent';
    const keycloakDetails = useKeycloak();
    const { isSpinnerNeeded , setGlobalConfigData,  setSplashScreen , setIsSpinnerNeeded , globalConfigData, selectedApp } = useContext(AppContext);
    const url = useRouteMatch();
    const url1 = window.location.pathname;

    const [fetchGlobalConfig
    ] = getLazyQuery(GET_GLOBAL_CONFIG)

    useEffect(() => {
        handleGlobalConfig();
    }, []);

    const [tileStatus, setTileStatus] = useState();

    const handleGlobalConfig = async () => {
        setIsSpinnerNeeded(true);
        try {
            const { data, error } = await fetchGlobalConfig();
            if (Object.keys(data?.getAppsGlobalConfigData || {})?.length) {
                const tileData = data.getAppsGlobalConfigData?.data?.find(
                    (item) => item?.name === "manage_tiles"
                )?.value;
                setTileStatus(tileData);
                let orgName = data.getAppsGlobalConfigData.organization.name;
                sessionStorage.setItem("ORG_NAME",orgName);
                setGlobalConfigData(data?.getAppsGlobalConfigData || {});
                let splashScreenValue = data?.getAppsGlobalConfigData?.data?.find(item => item?.name === 'splash_screen')?.value
                splashScreenValue && setSplashScreen(splashScreenValue);
            } 
        }
        finally {
            setGlobalLoad(false)
            setIsSpinnerNeeded(false);
        }
    };
    
    const handleMFERoute = tileStatus => {
        let routeList = tileStatus.map((childApp, i) => {
            return (childApp.hidden !== true && childApp.enabled === true && url1.includes(childApp.appKey.toUpperCase()) ? (
                <PrivateMicroRoute
                    component={MicroView}
                    useKeycloak={keycloakDetails}
                    history={History}
                    path={`/${childApp.appKey.toUpperCase()}/*`}
                    role={`view-${childApp.appKey}`}
                    name={
                        childApp.appKey.toUpperCase() +
                        childMainContentName
                    }
                    host={PLATFORM_URL+"/"+childApp.appKey.toUpperCase()}
                    menuName={childApp.title}
                    globalConfigData = {globalConfigData}
                    selectedApp={selectedApp}
                />
            ) : (
                ''
            ))}
        );
        return routeList;
    };
    return (
        <>  {globalLoad ?
            <GenwizardLoader />
            :
            <div style={{
                display:"flex",
                width:"100vw",
                height:"100vh",
                flexDirection:"column",
                position:"relative"
            }}>
                {isSpinnerNeeded && <GenwizardLoader />}
                <Header />
                <Breadcrumbs />
                <div style={{
                    display:"flex",
                    flexDirection:"column",
                    height:"85%",
                    width:"100%",
                    overflow:"auto"
                }}>
                    <Switch>
                        <Route exact path={Routes.SplashScreen} component={SplashScreen} />
                        <Route path={Routes.Manage} component={Manage} />
                        <Route path={Routes.Onboard} component={OnboardRoute} />
                        <Route path={Routes.ReleaseInfo} component={ReleaseInfo} />
                        {tileStatus && handleMFERoute(tileStatus)}
                    </Switch>
                </div>
                <Footer />
            </div>}
        </>
    );
};

export default RoutingModule;

const PrivateMicroRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                return (
                    <Component
                        useKeycloak={rest.useKeycloak}
                        history={rest.history}
                        role={rest.role}
                        name={rest.name}
                        host={rest.host}
                        globalConfigData={rest.globalConfigData}
                        selectedApp={rest.selectedApp}
                    />
                );
            }}
        />
    );
};
