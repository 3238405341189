/* eslint-disable */ 

/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    addAppBranchNameValidate,
    addAppDescValidate,
    addAppNameValidate,
    addAppPasswordValidate,
    addAppPathValidate,
    addAppRepoValidate,
    addAppUserNameValidate,
    bpmLevelFourValidate,
    businessDomainValidate,
    businessKPIsValidate,
    itKPIsValidate,
    bpmLevelOneValidate,
    bpmLevelThreeValidate,
    bpmLevelTwoValidate,
    validationFunction
} from '@components/ui/Forms/YupValidationSchema';
import useKeycloak from '@containers/keycloak/keycloak';
import { createUploadLink } from 'apollo-upload-client';
import CryptoJS from 'crypto-js';

export const decodeErrorMessage = (err, msg = 'Error Occurred') => {
    console.log('ERRR', err);
    if (err?.graphQLErrors && err?.graphQLErrors.length > 0) {
        return err.graphQLErrors[0].message;
    }
    if (err?.networkError?.result?.error) {
        return err?.networkError?.result?.error[0]?.message;
    }
    return msg;
    // return err?.networkError?.result?.errors[0]?.message;
};

export const getToken = () => {
    const { keycloak } = useKeycloak(process.env.REACT_APP_DEFAULT_REALM);
    return {
        Authorization: keycloak?.token ? `Bearer ${keycloak?.token}` : ''
    };
};

export const getUploadLink = keycloak => {
    const uploadLink = createUploadLink({
        uri: `${process.env.REACT_APP_PLATFORM_URL}/graphql`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: keycloak?.token ? `Bearer ${keycloak?.token}` : ''
        }
    });
    return uploadLink;
};

export const getUserName = () => {
    const { keycloak } = useKeycloak();
    // const userName = keycloak.tokenParsed.preferred_username.split('@')[0];
    const userName = keycloak.tokenParsed.name;
    return userName;
};

export const getLazyQuery = (query, variable = {}) => {
    return useLazyQuery(query, {
        variables: variable,
        context: {
            headers: getToken()
        },
        fetchPolicy: 'no-cache'
    });
};

export const getMutation = query => {
    return useMutation(query, {
        context: {
            headers: getToken()
        }
    });
};

export const logout = () => {
    const { keycloak } = useKeycloak();
    const myHeaders = new Headers();
    myHeaders.append(
        'Authorization',
        keycloak?.token ? `Bearer ${keycloak?.token}` : ''
    );
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('client_id', 'acpui');
    urlencoded.append(
        'refresh_token',
        keycloak?.refreshToken ? keycloak?.refreshToken : ''
    );
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };
    fetch(
        `${process.env.REACT_APP_PLATFORM_URL}/auth/realms/genwizard/protocol/openid-connect/logout`,
        requestOptions
    ).then(res => {
        window.location = `${process.env.REACT_APP_PLATFORM_URL}/genwizard/`;
        return res.json();
    });
};

export const getModuleFromLocation = (location, moduleName) => {
    const urlArray = location.pathname.split('/');
    return urlArray[1].includes(moduleName);
};

export const encryptData = (data, key) => {
    console.log(key , 'checking key')
    CryptoJS.algo.EvpKDF.cfg.hasher = CryptoJS.algo.SHA256.create();
    return CryptoJS.AES.encrypt(data, key).toString();
};

export const decryptData = (ciphertext, key) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    try {
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (err) {
        return null;
    }
};
// styling for child table
export const getScoreClass = score => {
    if (score.file_score > score.file_threshold) {
        return { backgroundColor: '#88ffb5' };
    }
    return {
        backgroundColor: '#ff7b7b'
    };

    // return score >= 0.8
    //     ? { backgroundColor: '#88ffb5' }
    //     : score > 0.5
    //     : { backgroundColor: '#ff7b7b' };
};

// styling for parent table
export const getRepoScoreClass = data => {
    let detectThreshold = false;
    if (data.status === 'Completed') {
        data?.files.forEach(eachFile => {
            if (eachFile.file_score < eachFile.file_threshold) {
                detectThreshold = true;
            }
        });
    }
    const detectFailed = data.files.some(
        eachFile => eachFile.status === 'Failed'
    );
    if (data.status === 'Inprogress' || data.status === 'Not-Started') {
        return {};
    }
    if (detectFailed || detectThreshold) {
        return {
            backgroundColor: '#ff7b7b'
        };
    }
    return { backgroundColor: '#88ffb5' };
};

export const getPdFData = async (
    payloadObject = {},
    downloadFileName = '',
    referencer = {},
    captureErrMsg = () => {},
    fromWiki = false
) => {
    const { keycloak } = useKeycloak();
    const myHeaders = new Headers();

    // fallback error msg
    const errMsg = 'Error in generating the report. Please try again later!';

    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append(
        'Authorization',
        keycloak?.token ? `Bearer ${keycloak?.token}` : ''
    );

    try {
        const initURL = `${process.env.REACT_APP_PLATFORM_URL}/${
            fromWiki ? 'getPDFReport' : 'getDataPDF'
        }`;
        const initRes = await fetch(initURL, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(payloadObject),

            redirect: 'follow'
        });

        if (!initRes.ok) {
            const errorData = await initRes.json();
            const errorMessage = errorData.message || errMsg;

            throw new Error(errorMessage);
        }

        const fethedData = await initRes.json();
        // myHeaders.append('Content-Type', 'application/json');
        // myHeaders.append('Authorization', `Bearer ${getToken()}`);

        const nameParser = fethedData?.PDF_path?.split('/').at(-1);

        // following api call which taks in path and gives file data
        try {
            const secURL = `${process.env.REACT_APP_PLATFORM_URL}/graphql/download/pdf`;

            const initDownloadRes = await fetch(secURL, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({ PDF_path: fethedData?.PDF_path }),

                redirect: 'follow'
            });

            if (!initDownloadRes.ok) {
                const errorData = await initDownloadRes.json();
                const errorMessage = errorData.message || errMsg;

                throw new Error(errorMessage);
            }
            const blob = await initDownloadRes.blob();
            const href = window.URL.createObjectURL(
                new Blob([blob], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
            );

            referencer.current.download = nameParser;
            referencer.current.href = href;
            referencer.current.click();
            captureErrMsg(prev => ({
                ...prev,
                isModalVisible: true,
                captureErr: fethedData?.message,
                status: 'success'
            }));
        } catch (err) {
            captureErrMsg(prev => ({
                ...prev,
                isModalVisible: true,
                captureErr:
                    fethedData?.message || fethedData['message '] || errMsg,
                status: 'failed'
            }));
        }
    } catch (err) {
        captureErrMsg(prev => ({
            ...prev,
            isModalVisible: true,
            captureErr: err.message ? err.message : errMsg,
            status: 'failed'
        }));
    }
};

export const getPdFDataDownload = async (payLoadData = {}, referencer = {}) => {
    const { keycloak } = useKeycloak();
    const myHeaders = new Headers();

    // fallback error msg
    const errMsg = 'Error in downloading the report. Please try again later!';

    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append(
        'Authorization',
        keycloak?.token ? `Bearer ${keycloak?.token}` : ''
    );
    // following api call which taks in path and gives file data
    try {
        const codeQualityURL = `${process.env.REACT_APP_PLATFORM_URL}/graphql/download/codequality`;

        const initDownloadRes = await fetch(codeQualityURL, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(payLoadData),

            redirect: 'follow'
        });

        if (!initDownloadRes.ok) {
            const errorData = await initDownloadRes.json();
            const errorMessage = errorData.message || errMsg;

            throw new Error(errorMessage);
        }
        const blob = await initDownloadRes.blob();
        const href = window.URL.createObjectURL(
            new Blob([blob], {
                type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
        );

        const nameParser = referencer?.repoName;

        referencer.current.download = nameParser;
        referencer.current.href = href;
        referencer.current.click();
    } catch (err) {
        return null;
    }
};

// yup validators

// validator provider

function provideValidationSchema(type) {
    console.log('TYPE', type);
    switch (type) {
        case 'appName':
            return addAppNameValidate;
        case 'appDesc':
            return addAppDescValidate;
        case 'businessDomain':
            return businessDomainValidate;
        case 'businessKPIs':
            return businessKPIsValidate;
        case 'itKPIs':
            return itKPIsValidate;
        case 'bpmLevel1':
            return bpmLevelOneValidate;
        case 'bpmLevel2':
            return bpmLevelTwoValidate;
        case 'bpmLevel3':
            return bpmLevelThreeValidate;
        case 'bpmLevel4':
            return bpmLevelFourValidate;
        case 'repoName':
            return addAppRepoValidate;
        case 'repoPath':
            return addAppPathValidate;
        case 'branchName':
            return addAppBranchNameValidate;
        case 'username':
            return addAppUserNameValidate;
        case 'pass':
            return addAppPasswordValidate;

        default: {}
    }
}

// master validate function
export const validateInput = (item, type, setterFunctn) => {
    console.log({ item, type, setterFunctn }, 'dskdskhd');
    validationFunction(
        provideValidationSchema(type),
        { [type]: item },
        {
            [type]: []
        }
    ).then(obj => {
        if (obj === undefined) {
            setterFunctn(prev => {
                return { ...prev, [type]: [] };
            });
        } else {
            setterFunctn(prev => {
                return { ...prev, [type]: [...obj[type]] };
            });
        }
    });
};
export const validateGrade = num => {
    if (num >= 0 && num < 10) return 'A';
    if (num >= 10 && num < 20) return 'B';
    if (num >= 20 && num < 30) return 'C';
    if (num >= 30 && num < 40) return 'D';
    if (num >= 40 && num < 5000) return 'E';
    else {
        return '';
    }
};
export const techColorProvider = incomingVal => {
    const grade =
        typeof incomingVal === 'string'
            ? incomingVal.toUpperCase()
            : validateGrade(Number(incomingVal));
    switch (grade.toUpperCase()) {
        case 'A':
            return { backgroundColor: ' #008731' };
        case 'B':
            return { backgroundColor: ' #3edd7b' };
        case 'C':
            return { backgroundColor: ' #ffbe00' };
        case 'D':
            return { backgroundColor: ' #ffdc77' };
        case 'E':
            return { backgroundColor: ' #ff7b7b' };
        default:
            return {};
    }
};

export const getSeverityType = type => {
    return type === 'CRITICAL'
        ? 'critical'
        : type === 'BLOCKER'
        ? 'blocker'
        : type === 'HIGH'
        ? 'major'
        : type === 'MEDIUM'
        ? 'bug'
        : type === 'LOW'
        ? 'minor'
        : 'all';
};

export const critcalSeverity = techDataAr =>
    techDataAr.filter(eachItem => {
        const severity = eachItem?.severity?.toUpperCase();

        return severity === 'CRITICAL';
    });
export const blockers = techDataAr =>
    techDataAr.filter(eachItem => {
        const severity = eachItem?.severity?.toUpperCase();

        return severity === 'BLOCKER';
    });
export const highSeverity = techDataAr =>
    techDataAr.filter(eachItem => {
        const severity = eachItem?.severity?.toUpperCase();

        return severity === 'HIGH' || severity === 'MAJOR';
    });
export const mediumSeverity = techDataAr =>
    techDataAr.filter(eachItem => {
        const severity = eachItem?.severity?.toUpperCase();

        return severity === 'MEDIUM' || severity === 'BUG';
    });
export const lowSeverity = techDataAr =>
    techDataAr.filter(eachItem => {
        const severity = eachItem?.severity?.toUpperCase();

        return severity === 'LOW' || severity === 'MINOR';
    });
