import { gql } from '@apollo/client';

// Onboard.js / CurateApp.js
export const ONBOARD_APP_LIST = gql`
    query getAppList($orgName: String) {
        getAppList(orgName: $orgName)
    }
`;

export const GET_REPO_LIST = gql`
    query getRepoList($appName: String!, $orgName: String!) {
        getRepoList(filter: { appName: $appName, orgName: $orgName }) {
            repoName
            selectedFiles
            repoUrl
            module
            type
            repoPath
            branch
        }
    }
`;


export const GET_BPM_LEVELS = gql`
    query getbpmLevels(
        $paramHeader: String
        $paramKey: String
        $orgName: String
    ) {
        getbpmLevels(
            filter: {
                paramHeader: $paramHeader
                paramKey: $paramKey
                orgName: $orgName
            }
        )
    }
`;

export const GET_CATAGORY_LIST = gql`
    query getcategoryList($orgName: String) {
        getcategoryList(orgName: $orgName)
    }
`;


export const FORM_BASED_ADD_APP = gql`
    mutation formBasedAddApp(
        $appName: String!
        $orgName: String!
        $description: String!
        $businessDomain: String!
        $businessKPIs: String!
        $itKPIs: String!
        $l1BusinessProcess: String!
        $l2BusinessProcess: String!
        $l3BusinessProcess: String!
        $attachments: [Upload]!
        $reposDetails: [reposDetailsInput]!
    ) {
        formBasedAddApp(
            input: {
                appName: $appName
                orgName: $orgName
                description: $description
                businessDomain: $businessDomain
                businessKPIs: $businessKPIs
                attachments: $attachments
                itKPIs: $itKPIs
                l1BusinessProcess: $l1BusinessProcess
                l2BusinessProcess: $l2BusinessProcess
                l3BusinessProcess: $l3BusinessProcess
                reposDetails: $reposDetails
            }
        )
    }
`;


export const GET_CONFIG = gql`
    query getConfigCollectionData($orgName: String!, $appName: String!) {
        getConfigCollectionData(
            filter: { appName: $appName, orgName: $orgName }
        )
    }
`;



export const VALIDATE_GIT_CREDENTIALS = gql`
    query gitCredentialsValidation(
        $repoUrl: String!
        $repoUsername: String!
        $repoPassword: String!
    ) {
        gitCredentialsValidation(
            filter: {
                repoUrl: $repoUrl
                repoUsername: $repoUsername
                repoPassword: $repoPassword
            }
        )
    }
`;

export const GET_GLOBAL_CONFIG = gql`
query { getAppsGlobalConfigData }`