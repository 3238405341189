import React from 'react';

import ExpandableRow from '../row/ExpandableRow';
import Row from '../row/Row';

import BodyText from './BodyText';
// import ColumnSpan from './ColumnSpan';
import TableHeader from './TableHeader';
import "./CurrentRuns.css"

const Table = ({
    isLoading,
    colspan,
    showHeader,
    columns,
    isError,
    currentPageData,
    isClickable,
    onClickHandler,
    // stepperColumns,
    extraColumns,
    curateTableFlag,
    appSummaryTableFlag,
    techDebtIssueFlag,
    isSearch = false
}) => {
    return (
        <div
            className={
                curateTableFlag
                    ? 'run-c-table-responsive c-app-table -customScroll'
                    : appSummaryTableFlag
                    ? 'run-c-table-responsive c-app-summary-table -customScroll'
                    : techDebtIssueFlag
                    ? 'c-table-responsive -customScroll c-issue-table-details'
                    : 'run-c-table-responsive -customScroll'
            }
        >
            <table
                className={
                    curateTableFlag
                        ? 'c-table c-table-fixed'
                        : 'c-table -hover c-table-fixed'
                }
            >
                {/* <ColumnSpan span={colspan} /> */}

                {showHeader && (
                    <TableHeader
                        columns={columns}
                        curateTableFlag={curateTableFlag}
                        appSummaryTableFlag={appSummaryTableFlag}
                        techDebtIssueFlag={techDebtIssueFlag}
                    />
                )}
                <tbody>
                    {isLoading && !isError ? (
                        <BodyText text="Loading....." />
                    ) : currentPageData.length === 0 || isError || isSearch ? (
                        <BodyText text="No Records Found" />
                    ) : (
                        currentPageData.map((item, index) => (
                            <>
                                <Row
                                    key={index}
                                    isClickable={isClickable}
                                    index={index}
                                    onRowClick={() => onClickHandler(item)}
                                    item={item}
                                    columns={columns}
                                    isLastRow={
                                        index === currentPageData.length - 1
                                    }
                                ></Row>
                                {item.isExpanded && (
                                    <ExpandableRow
                                        key={index}
                                        extraColumns={extraColumns}
                                        colSpan={colspan}
                                        index={index}
                                        item={item}
                                    ></ExpandableRow>
                                )}
                            </>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
