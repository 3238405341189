import { useEffect, useRef } from 'react';

const useClickOutside = handler => {
    const node = useRef();

    useEffect(() => {
        const customHandler = event => {
            if (node.current && !node.current.contains(event.target)) {
                handler();
            }
        };
        document.addEventListener('mousedown', customHandler);

        return () => {
            document.removeEventListener('mousedown', customHandler);
        };
    });

    return node;
};

export default useClickOutside;
