export const ESO_ENABLED = process.env.REACT_APP_MS_ESO_ENABLED === 'true';
export const MULTI_TENANT = process.env.REACT_APP_MULTI_TENANT === 'true';
export const DEFAULT_REALM = 'genwizard';
export const REACT_APP_PLATFORM_URL = process.env.REACT_APP_PLATFORM_URL;
export const PUBLIC_URL = 'localhost:3000'
export const KC_BACKEND_CLIENT_ID = 'acpui';
export const CLOUD_TYPE = '###CLOUD_TYPE###';
export const PLATFORM_TYPE = '###PLATFORM_TYPE###';
export const ORG_NAME = '###ORG_NAME###';
export const key = process.env.REACT_APP_SECRET_VAULT_KEY
export const SOCKET_URL = '###SOCKET_URL###';
// export const EXTERNAL_LINK = '{"ExplainChild":{"appKey": "exp","pathname":"http://localhost:3002/EXP" },"WikiChild":{"appKey": "wiki","pathname":"https://genwizardadmfeature-uat.acnengineering.com/WIKI" }, "CodeQualityChild":{"appKey": "cdq","pathname":"https://genwizardadmfeature-uat.acnengineering.com/CDQ" }, "GraphChild":{"appKey": "gph","pathname":"https://genwizardadmfeature-uat.acnengineering.com/GPH" }}';
// export const EXTERNAL_LINK = '{"child1":{"appKey": "ch", "activeStatus": true, "childName": "child1", "image": "codeQuality", "description":"desc will go here","pathname":"http://localhost:4001" }}';
export const EXTERNAL_LINK = JSON.stringify({
           ExplainChild: {
               appKey: 'exp',
               pathname: 'https://genwizardadmfeatures-sit.acnengineering.com/EXP'
           },
           WikiChild: {
               appKey: 'wiki',
               pathname:
                   'https://genwizardadmfeatures-sit.acnengineering.com/WIKI'
           },
           CodeQualityChild: {
               appKey: 'cdq',
               pathname:
                   'https://genwizardadmfeatures-sit.acnengineering.com/CDQ'
           },
           GraphChild: {
               appKey: 'gph',
               pathname:
                   'https://genwizardadmfeatures-sit.acnengineering.com/GPH'
           },
           explore: {
            appKey: 'explore',
            pathname:
                'https://genwizardadmfeatures-sit.acnengineering.com/EXPLORE'
           }
       })
