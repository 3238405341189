import React, { useEffect, useState } from 'react';

const FilterChip = ({ filterData, setFilterData }) => {
    const [listArr, setListArr] = useState([
        { data: ['Retail'] },
        { data: ['Operating process'] },
        { data: ['Sourcing'] }
    ]);

    const handleItemRemoval = key => {
        const obj = {};
        Object.keys(filterData).forEach(keyName => {
            if (keyName !== key) obj[keyName] = filterData[keyName];
        });

        setFilterData(obj);
        // let index = 0;
        // filterTableData[0].find(function(item, i) {
        //     if (item.selectBoxId === selectBoxId) {
        //         index = i;
        //     }
        // });
        // const finalList = filterTableData;
        // const changedColumn = filterTableData[0][index];
        // const dataArr = changedColumn.data;
        // for (let i = 0; i < dataArr.length; i++) {
        //     if (dataArr[i].value == key) {
        //         dataArr[i].isChecked = false;
        //     }
        // }
        // changedColumn.data = dataArr;
        // finalList[0][index] = changedColumn;
        // setFilterTableData(finalList);
        // GetAllCheckedValuesFinal();
        // setApplyButtonTrigger(!applyButtonTrigger);
    };

    const handleRemoveAll = () => {
        setFilterData({});
    };

    // useEffect(() => {

    // }, [listArr]);

    return Object.keys(filterData).length > 0 ? (
        <div className="c-filter-list" style={{ marginTop: '2rem' }}>
            {Object.keys(filterData).map(item => {
                return (
                    <a
                        href="javascript:;"
                        className="c-filter-item"
                        onClick={() => handleItemRemoval(item)}
                    >
                        {filterData[item]}
                        <i className="icon-cross"></i>
                    </a>
                );
            })}

            <a
                href="javascript:;"
                className="c-filter-clear"
                onClick={() => handleRemoveAll()}
            >
                Clear all filters
            </a>
            {/* <div className="myw-filter-btn">
                <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    style={{ height: 'auto' }}
                    onClick={e => handleRemoveAll()}
                >
                    Clear All
                </button>
            </div> */}
        </div>
    ) : (
        ''
    );
};

export default FilterChip;
