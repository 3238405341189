import React from 'react';
import PropTypes from 'prop-types';

function Stepper({ steps, currentStep, handleStep }) {
    return (
        <div className="c-stepper">
            <div className="c-stepper__wrapper">
                <div className="c-stepper__row">
                    {steps.map((step, index) => {
                        console.log(currentStep, index, 'currentStep');
                        return (
                            <div
                                key={index}
                                className={`c-stepper__step ${
                                    index === currentStep ? '-active' : ''
                                } ${index < currentStep ? '-completed' : ''}`}
                            >
                                <div className="c-stepper__circle-wrap">
                                    <a
                                        onClick={
                                            currentStep > index
                                                ? () => handleStep(index)
                                                : () => {}
                                        }
                                        href="javascript:;"
                                        type="button"
                                        className="c-stepper__circle"
                                    ></a>
                                </div>
                                <p className="c-stepper__text">{step}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
Stepper.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.string),
    currentStep: PropTypes.number,
    handleStep: PropTypes.func
};

Stepper.defaultProps = {
    steps: [],
    currentStep: 0,
    handleStep: () => {}
};

export default Stepper;
