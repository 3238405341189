const CONSTANTS = {
    addRepo: {
        repoName: {
            required: {
                message: 'Repository name is required.'
            },
            min: {
                length: 3,
                message: 'Must atleast be 3 characters long'
            },
            max: {
                length: 64,
                message: 'Must not exceed 64 characters'
            },
            match: {
                criteria: /^[A-Za-z0-9 .,_"'-]+$/,
                message:
                    'No special characters allowed except space . , _ " \'.'
            }
        },
        repoPath: {
            required: {
                message: 'Path is Required.'
            },
            min: {
                length: 3,
                message: 'Must atleast be 3 characters long.'
            },
            max: {
                length: 128,
                message: 'Must not exceed 128 characters in length.'
            },
            match: {
                criteria: /^((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))[a-zA-Z0-9_.-]+(\.[a-zA-Z0-9:]+)*((\/)[\w#-]+)*(\/\w+(\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?)?(\?([\w-.]+(=[\w-.]*)?(&[\w-.]+(=[\w-.]*)?)*)?$)?/gm,

                message: 'Enter valid API Endpoint URL.'
            }
        },
        branchName: {
            required: {
                message: 'Branch Name is required.'
            },
            min: {
                length: 3,
                message: 'Must atleast be 3 characters long.'
            },
            max: {
                length: 64,
                message: 'Must not exceed 64 characters.'
            },
            match: {
                criteria: /^[A-Za-z0-9 .,_"'-]+$/,
                message:
                    'No special characters allowed except space . , _ " \'.'
            }
        },
        username: {
            required: {
                message: 'Username is required.'
            },
            min: {
                length: 3,
                message: 'Must atleast be 3 characters long.'
            },
            max: {
                length: 64,
                message: 'Must not exceed 64 characters in length.'
            },
            match: {
                criteria: /^[A-Za-z0-9@._-]+$/,
                message:
                    'No special characters allowed except at ("@") / underscore ("_") / hyphen ("-") /dot (".").'
            }
        },
        pass: {
            required: {
                message: 'Password is required.'
            },
            min: {
                length: 8,
                message: 'Must atleast be 8 characters long.'
            },
            max: {
                length: 64,
                message: 'Must not exceed 64 characters in length.'
            }
        }
    },

    addApp: {
        appName: {
            required: {
                message: 'App Name is required.'
            },
            max: {
                length: 30,
                message: 'Length must not exceed 30 characters.'
            },
            min: {
                length: 3,
                message: 'Must atleast be 3 characters long'
            },
            match: {
                criteria: /^[A-Za-z0-9_-]+$/,
                message: 'No special characters allowed except - and _'
            }
        },
        appDesc: {
            required: {
                message: 'App Description is required'
            },
            min: {
                length: 5,
                message: 'Must atleast be 5 characters long'
            },
            max: {
                length: 64,
                message: 'Length must not exceed 64'
            },
            match: {
                criteria: /^[A-Za-z0-9 _-]+$/,
                message: 'No special characters allowed except - and _'
            }
        },
        businessDomain: {
            required: {
                message: 'Business Domain is required'
            },
            min: {
                length: 5,
                message: 'Must atleast be 5 characters long'
            },
            max: {
                length: 30,
                message: 'Length must not exceed 64'
            },
            match: {
                criteria: /^[A-Za-z0-9_-]+$/,
                message: 'No special characters allowed except - and _'
            }
        },
        businessKPIs: {
            required: {
                message: 'Business KPIs is required'
            },
            min: {
                length: 5,
                message: 'Must atleast be 5 characters long'
            },
            max: {
                length: 30,
                message: 'Length must not exceed 64'
            },
            match: {
                criteria: /^[A-Za-z0-9_-]+$/,
                message: 'No special characters allowed except - and _'
            }
        },
        itKPIs: {
            required: {
                message: 'IT KPIs is required'
            },
            min: {
                length: 5,
                message: 'Must atleast be 5 characters long'
            },
            max: {
                length: 30,
                message: 'Length must not exceed 64'
            },
            match: {
                criteria: /^[A-Za-z0-9_-]+$/,
                message: 'No special characters allowed except - and _'
            }
        },
        bpmLevel1: {
            required: {
                message: 'BPM Level 1 is required'
            },
            min: {
                length: 5,
                message: 'Must atleast be 5 characters long'
            },
            max: {
                length: 30,
                message: 'Length must not exceed 64'
            },
            match: {
                criteria: /^[A-Za-z0-9_-]+$/,
                message: 'No special characters allowed except - and _'
            }
        },
        bpmLevel2: {
            required: {
                message: 'BPM Level 2 is required'
            },
            min: {
                length: 5,
                message: 'Must atleast be 5 characters long'
            },
            max: {
                length: 30,
                message: 'Length must not exceed 64'
            },
            match: {
                criteria: /^[A-Za-z0-9_-]+$/,
                message: 'No special characters allowed except - and _'
            }
        },
        bpmLevel3: {
            required: {
                message: 'BPM Level 3 is required'
            },
            min: {
                length: 5,
                message: 'Must atleast be 5 characters long'
            },
            max: {
                length: 30,
                message: 'Length must not exceed 64'
            },
            match: {
                criteria: /^[A-Za-z0-9_-]+$/,
                message: 'No special characters allowed except - and _'
            }
        },
        bpmLevel4: {
            required: {
                message: 'BPM Level 4 is required'
            },
            min: {
                length: 5,
                message: 'Must atleast be 5 characters long'
            },
            max: {
                length: 30,
                message: 'Length must not exceed 64'
            },
            match: {
                criteria: /^[A-Za-z0-9_-]+$/,
                message: 'No special characters allowed except - and _'
            }
        }
    }
};
export default CONSTANTS;
