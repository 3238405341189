import React, { useEffect, useState, useContext } from 'react';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { Routes } from '@constants/RoutingConstants';
import Tippy from '@tippyjs/react';
import { getUserName, logout } from '@utils/Util';

import AppContext from '@context/app/AppContext';
import useKeycloak from '../../containers/keycloak/keycloak';
import useClickOutside from '../../hooks/UseClickOutside';

const userIconStyle = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1.93rem',
    minWidth: '1.93rem',
    maxWidth: '1.93rem',
    borderRadius: '8.4rem',
    fontSize: '0.7rem',
    fontWeight: ' 500',
    textTransform: 'uppercase'
};
const nestedIconStyle = {
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1.93rem',
    minWidth: '1.93rem',
    maxWidth: '1.93rem',
    borderRadius: '8.4rem',
    fontSize: '0.7rem',
    fontWeight: ' 500',
    textTransform: 'uppercase',
    pointerEvents: 'none'
};

const nestedBtnStyle = {
    marginLeft: '10px',
    marginTop: '4px',
    cursor: 'default',
    pointerEvents: 'none'
};

const Header = () => {
    const location = useLocation();
    const { keycloak } = useKeycloak();
    const [toggle, setToggle] = useState(false);

    const usertitle = () => {
        return (
            keycloak.tokenParsed.given_name[0] +
            keycloak.tokenParsed.family_name[0]
        );
    };

    const { globalConfigData } = useContext(AppContext);

    var assetHeader, disclaimerDescription ; 
    globalConfigData.data.map((obj)=>{
        if(obj.name === "asset_Header") {
            assetHeader = obj.value
        }
        else if(obj.name === "disclaimer_description"){
            disclaimerDescription = obj.value;
        }
    })

    const userProfileButtonNode = useClickOutside(() => {
        setToggle(false);
    });

    useEffect(() => {
        setToggle(false);
    }, [location.pathname]);

    const ProfileDropDown = () => {
        return                 <div className="c-app__header-nav">
        <div
            ref={userProfileButtonNode}
            className={`c-dropdown -right -static -ia-dropdown c-appdropdown ${
                toggle ? '-open' : ''
            }`}
        >
            <a
                href="javascript:;"
                className="c-app__header-item"
                // title="demo.user"
                aria-expanded={toggle ? 'true' : 'false'}
                data-toggle="dropdown"
                onClick={() => setToggle(prev => !prev)}
            >
                <Tippy content={getUserName()} placement="bottom">
                    <button
                        style={userIconStyle}
                        type="button"
                        data-display="static"
                        data-toggle="dropdown"
                        id="loginDetails"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                    >
                        {usertitle()}
                    </button>
                </Tippy>
                {/* <span className="sr-only">demo.user</span> */}
            </a>
            <div className="c-dropdown-menu">
                <div className="c-dropdown-body">
                    <div className="c-dropdown-item">
                        <div className="c-ia-dropdown__inner">
                            <button
                                style={nestedIconStyle}
                                type="button"
                                data-display="static"
                                data-toggle="dropdown"
                                id="loginDetails"
                                // aria-haspopup="true"
                                // aria-expanded="false"
                            >
                                {usertitle()}
                            </button>
                            <div className="c-ia-dropdown__row">
                                <div
                                    className="-medium"
                                    style={nestedBtnStyle}
                                >
                                    {getUserName()}
                                </div>
                                {/* <div className="-light">
                                    Accenture Leadership
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="c-dropdown-divider"></div>
                    <a
                        className="c-dropdown-item -align-center"
                        role="button"
                        href="javascript:;"
                        style={{
                            pointerEvents: 'none',
                            backgroundColor: 'lightgray',
                            marginBottom: '1px'
                        }}
                    >
                        <i className="icon-notify -icon"></i>
                        <span className="-desc">Notifications</span>
                    </a>
                    <a
                        className="c-dropdown-item -align-center"
                        role="button"
                        href="javascript:;"
                        style={{
                            pointerEvents: 'none',
                            backgroundColor: 'lightgray'
                        }}
                    >
                        <i className="icon-help -icon"></i>
                        <span className="-desc">Help</span>
                    </a>
                    <Link
                        to={Routes.ReleaseInfo}
                        className="c-dropdown-item -align-center"
                    >
                        <i className="-release-info"></i>
                        <span className="-desc">Release Info</span>
                    </Link>
                    <Link
                        to={Routes.Onboard}
                        className="c-dropdown-item -align-center"
                    >
                        <i className="icon-onboard -icon"></i>
                        <span className="-desc">Onboard</span>
                    </Link>
                    <a
                        className="c-dropdown-item -align-center -logout"
                        onClick={() => logout()}
                        role="button"
                        href="javascript:;"
                    >
                        Logout
                    </a>
                </div>
            </div>
        </div>
    </div>
    }

    return (
        <header style={{display:"flex",flexDirection:"column",width:"100%",height:"auto",justifyContent:"space-between",position:"sticky",top:0,left:0,right:0,zIndex:999,background:"#fff"}}>
            <div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"0.75rem 4.75rem",justifyContent:"space-around",width:"100%",borderBottom:"2px solid #6c15b6",}}>
                <div className="c-app__header-brand">
                    <span style={{fontWeight:"500"}}>
                        {assetHeader || "GenWizard for ADM"}
                    </span>
                </div>
                <ProfileDropDown/>
            </div>
            <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center",padding:"0.75rem 4.75rem",
                boxShadow: '0 0.125rem 0.125rem rgba(0, 0, 0, 0.08)'
                }}>
                <span style={{ color: 'rgb(161, 0, 255)' }}>
                    {disclaimerDescription || 'Disclaimer: Generative AI output is a recommendation only and not a substitute for human judgement and diligence'}
                </span>
            </div>
        </header>
    );
};

export default Header;
