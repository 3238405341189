import React, { createRef } from 'react';
import useKeycloak from '@containers/keycloak/keycloak';
import { downloadBtn, excelIcon } from '@utils/ImageUtils';

const FileDownload = ({ url, filename }) => {
    const { keycloak } = useKeycloak(process.env.REACT_APP_DEFAULT_REALM);
    const downloadRef = createRef();
    const handleFileDownload = async () => {
        if (downloadRef.current.href) {
            return;
        }

        const result = await fetch(url, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: keycloak?.token
                    ? `Bearer ${keycloak?.token}`
                    : ''
            }
        });

        const blob = await result.blob();
        const href = window.URL.createObjectURL(
            new Blob([blob], {
                type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
        );

        downloadRef.current.download = filename;
        downloadRef.current.href = href;
        downloadRef.current.click();
    };
    return (
        <div className="c-upload-link">
            <div className="c-upload-link__title">
                Download Predefined Template
            </div>
            <a
                ref={downloadRef}
                role="button"
                className="c-upload-link__Download"
                style={{ cursor: 'pointer', outline: 'none' }}
                onClick={handleFileDownload}
                onKeyDown={() => {}}
                tabIndex="0"
            >
                <img src={excelIcon} alt="file" role="presentation" />
                <span>Download Template.xlsx</span>
                <img src={downloadBtn} alt="Download" role="presentation" />
            </a>
        </div>
    );
};

export default FileDownload;
