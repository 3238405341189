
/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppHeader from '@components/layout/AppHeader';
import SelectCardFooter from '@components/layout/SelectCardFooter';
import StatusModal from '@components/modals/statusModal/StatusModal';
import RadioBtnGroup from '@components/radio/RadioBtnGroup';
import ColumnConstants from '@components/table/column/ColumnConstants';
import GenwizardTable from '@components/table/GenwizardTable';
import { REACT_APP_PLATFORM_URL } from '@constants/Constants';
import { Routes } from '@constants/RoutingConstants';
import AppContext from '@context/app/AppContext';
import { SUBMIT_FOR_ONBOARD_APP } from '@graphql/mutations';
import { errorImg, successStatusTick, successTick } from '@utils/ImageUtils';
import { decodeErrorMessage, getMutation } from '@utils/Util';

import FileDownload from './FileDownload';
import FileUpload from './FileUpload';

const OnboardApp = () => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isFileDropped, setIsFileDropped] = useState(false);
    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState();
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [selectedRadioBtn, setSelectedRadioBtn] = useState('excel');
    const [responseData, setResponseData] = useState('');

    // Modal states
    const [success, setSuccess] = useState();
    const [showDialog, setShowDialog] = useState();
    const [errorMessage, setErrorMessage] = useState();

    // Spinner
    const { setIsSpinnerNeeded } = useContext(AppContext);

    const [submitApps] = getMutation(SUBMIT_FOR_ONBOARD_APP);

    const handleRowRemoval = item => {
        const updatedData = tableData.filter(
            row => row.appName !== item.appName
        );
        if (updatedData.length === 0) {
            setIsFileDropped(false);
        }
        setTableData(updatedData);
    };

    const performChildAction = (tableAction, tableRowData) => {
        switch (tableAction) {
            case 'removeRow':
                return handleRowRemoval(tableRowData);
            default:
                return '';
        }
    };

    const clearUploadedData = () => {
        setTableData([]);
        setIsFileDropped(false);
        setIsFileUploaded(false);
        setFileName('');
        setFileSize('');
    };

    const handleSubmit = async () => {
        setIsSpinnerNeeded(true);
        try {
            const response = await submitApps({
                variables: {
                    appsDetails: tableData,
                    orgName: sessionStorage.getItem("ORG_NAME")
                }
            });
            setResponseData(response);
            setSuccess(true);
            setShowDialog(true);
            setIsSpinnerNeeded(false);
        } catch (err) {
            setSuccess(false);
            setErrorMessage(err);
            setShowDialog(true);
            setIsSpinnerNeeded(false);
        }
    };

    const handleCancel = () => {
        history.push(Routes.OnboardApps);
    };

    useEffect(() => {
        setTableData(data);
    }, [data]);

    return (
        <>
            <>
                <div className="app-wrap">
                    <AppHeader title="Import Application" />
                    <div className="c-index c-onboard">
                        <div className="app-container">
                            <RadioBtnGroup
                                radioGroupName="onboard-app"
                                radioBtnList={[
                                    {
                                        id: 'excel',
                                        label: 'Excel-Based Template Onboard'
                                    }
                                    // {
                                    //     id: 'cmdb',
                                    //     label: 'Import from CMDB'
                                    // }
                                ]}
                                selectedRadioBtn={selectedRadioBtn}
                                setSelectedRadioBtn={setSelectedRadioBtn}
                            />
                            <FileDownload
                                url={`${REACT_APP_PLATFORM_URL}/graphql/download`}
                                filename="add_app_template.xlsx"
                            />
                            <h6 className="c-repo__title">
                                Upload Completed Template
                            </h6>
                            {isFileDropped ? (
                                <div className="c-file">
                                    <div className="c-file__header">
                                        Uploading File
                                    </div>
                                    <div className="c-file__body">
                                        {/* <div className="c-upload-progress">
                                        <div className="c-upload-progress__title">
                                            File&lt;Name&gt;.xls
                                        </div>
                                        <div className="c-upload-progress__fill">
                                            <div className="-progress">
                                                <div
                                                    className="-progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow="90"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                    style={{ width: '90%' }}
                                                ></div>
                                            </div>
                                            <div className="c-upload-progress__value">
                                                <span>90% : 43 sec left</span>
                                                <a
                                                    href="javascript:;"
                                                    className="-pause"
                                                ></a>
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="c-file__content">
                                            <div className="c-file__name">
                                                <img
                                                    src={successTick}
                                                    alt="success"
                                                />
                                                {fileName}
                                            </div>
                                            <span className="c-file__size">
                                                {fileSize}
                                            </span>
                                            <a
                                                href="javascript:;"
                                                className="c-file__delete icon-delete"
                                                onClick={clearUploadedData}
                                            ></a>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <FileUpload
                                    setFileName={setFileName}
                                    setFileSize={setFileSize}
                                    setIsFileDropped={setIsFileDropped}
                                    setIsFileUploaded={setIsFileUploaded}
                                    setData={setData}
                                />
                            )}
                            {isFileUploaded && tableData.length > 0 && (
                                <>
                                    <h6 className="c-repo__title">
                                        Review your Apps to onboard
                                    </h6>
                                    <GenwizardTable
                                        colData={ColumnConstants(
                                            'importAppPageTable',
                                            performChildAction
                                        )}
                                        data={tableData}
                                        isLoading={false}
                                        isError={false}
                                        isPagination={false}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <SelectCardFooter
                    primaryBtnLabel="Submit"
                    secondaryBtnLabel="Cancel"
                    handlePrimaryBtnClick={handleSubmit}
                    handleSecondaryBtnClick={handleCancel}
                    isPrimaryBtnDisabled={tableData.length === 0}
                />
            </>
            {showDialog && (
                <StatusModal
                    text1={
                        success
                            ? responseData?.data?.addApp
                            : decodeErrorMessage(errorMessage)
                    }
                    image={success ? successStatusTick : errorImg}
                    isModalOpen={showDialog}
                    closeModal={() => {
                        setShowDialog(false);
                        success ? history.push(Routes.OnboardApps) : '';
                    }}
                    altMsg={success ? 'Success' : 'Error'}
                />
            )}
        </>
    );
};

export default OnboardApp;
