import React, { useEffect, useState } from 'react';

import Pagination from '../pagination/Pagination';

import Table from './component/Table';

const GenwizardTable = ({
    colData = {},
    data = [],
    showHeader = true,
    isLoading,
    isError,
    isPagination,
    isClickable,
    onClickHandler,
    curateTableFlag,
    appSummaryTableFlag,
    techDebtIssueFlag = false,
    isSearch = false,
    isChildTable,
    setSearchModData = () => {},
    publishList,
    performChildAction,
    isDraggable = false,
    handleDraggble = () => {},
    tableName = ''
}) => {
    const { colspan = [], columns = [], extraColumns = [] } = colData;

    const [currentPageData, setCurrentPageData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumbers, setPageNumbers] = useState(1);
    const [validPageNumber, setValidPageNumber] = useState(1);

    useEffect(() => {
        setCurrentPage(1);
    }, [pageNumbers]);

    useEffect(() => {
        if (isPagination) {
            setPageNumbers(Math.ceil(data.length / 5));
            const tableData = data.slice(
                (currentPage - 1) * 5,
                currentPage * 5
            );
            setCurrentPageData(tableData);
        } else {
            setCurrentPageData(data);
        }
    }, [data]);

    useEffect(() => {
        let tableData = data;
        tableData = tableData.slice((currentPage - 1) * 5, currentPage * 5);
        if (isPagination) {
            if (
                currentPage !== '' &&
                currentPage <= pageNumbers &&
                currentPage > 0
            ) {
                setValidPageNumber(currentPage);
                setCurrentPageData(tableData);
            } else if (currentPage !== '') {
                setCurrentPage(validPageNumber);
            }
        } else {
            setCurrentPageData(data);
        }
    }, [currentPage]);

    return (
        <>
            <Table
                isLoading={isLoading}
                colspan={colspan}
                showHeader={showHeader}
                columns={columns}
                isError={isError}
                currentPageData={currentPageData}
                isClickable={isClickable}
                onClickHandler={onClickHandler}
                extraColumns={extraColumns}
                curateTableFlag={curateTableFlag}
                techDebtIssueFlag={techDebtIssueFlag}
                appSummaryTableFlag={appSummaryTableFlag}
                isSearch={isSearch}
                isChildTable={isChildTable}
                setSearchModData={setSearchModData}
                publishList={publishList}
                performChildAction={performChildAction}
                isDraggable={isDraggable}
                handleDraggble={handleDraggble}
                tableName={tableName}
            ></Table>

            {isPagination && (
                <Pagination
                    pageNumbers={pageNumbers}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    data={data}
                ></Pagination>
            )}
        </>
    );
};

export default GenwizardTable;
