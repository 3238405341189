import React, { useMemo, useState } from 'react';
import Tippy from '@tippyjs/react';
const BPM_CONSTANT = {
    businessDomain: 'Business Domain',
    l1BusinessProcess: 'L1 Business Process',
    l2BusinessProcess: 'L2 Business Process',
    l3BusinessProcess: 'L3 Business Process',
    businessKPIs: 'Business KPI',
    itKPIs: 'IT KPI'
};

const ExpandableChip = ({ levels, notInTable, initDisplayCount }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const objectKeys = Object.keys(levels);

    const derivedObject = useMemo(() => {
        const keysToShow = isExpanded
            ? objectKeys
            : objectKeys.slice(0, initDisplayCount);
        return keysToShow.reduce((obj, key) => {
            obj[key] = levels[key];
            return obj;
        }, {});
    }, [isExpanded]);

    // remaing count
    const remCount = objectKeys.length - initDisplayCount;

    return (
        <>
            <div
                className={notInTable ? 'c-chip__list' : 'c-chip__onboard-list'}
            >
                {Object.keys(derivedObject).map((item, index) => (
                    <>
                        {levels[item] ? (
                            <span
                                key={index}
                                style={{ gap: '0.2em' }}
                                className="c-chip"
                            >
                                <span
                                    style={{
                                        fontWeight: '600',
                                        fontSize: 'smaller'
                                    }}
                                >
                                    {BPM_CONSTANT[item]}
                                </span>
                                <span>:</span>
                                <span>{levels[item]}</span>
                            </span>
                        ) : null}
                    </>
                ))}
            </div>
            {!isExpanded && (
                <Tippy content="Click to show" placement="bottom">
                    <small
                        role="none"
                        style={{
                            color: '#a100ff',
                            cursor: 'pointer'
                        }}
                        onKeyDown={() => {}}
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        +{remCount} more
                    </small>
                </Tippy>
            )}
        </>
    );
};

const BpmLevelChip = ({
    levels,
    notInTable,
    headerConstants,
    shouldBeExpanded = false,
    initDisplayCount = 1
}) => {
    return (
        <>
            {shouldBeExpanded ? (
                <ExpandableChip
                    headerConstants={headerConstants}
                    levels={levels}
                    notInTable={notInTable}
                    initDisplayCount={initDisplayCount}
                />
            ) : (
                <div
                    className={
                        notInTable ? 'c-chip__list' : 'c-chip__onboard-list'
                    }
                >
                    {Object.keys(levels).map((item, index) => (
                        <>
                            {levels[item] ? (
                                <span key={index} className="c-chip">
                                    <span
                                        style={{
                                            fontWeight: '600',
                                            fontSize: 'smaller'
                                        }}
                                    >
                                        {BPM_CONSTANT[item]}
                                    </span>
                                    : {levels[item]}
                                </span>
                            ) : null}
                        </>
                    ))}
                </div>
            )}
        </>
    );
};

export default BpmLevelChip;
