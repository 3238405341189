import React, { useState } from 'react';

import AppContext from './AppContext';

const AppContextProvider = ({ children }) => {
    const [showLoader, setShowLoader] = useState(false);
    const [globalConfigData , setGlobalConfigData] = useState({})
    // const [accessToken, setAccessToken] = useState('');
    // const [accessDetails, setAccessDetails] = useState('');
    // const [authorizedView, setAuthorizedView] = useState('');
    const [roles, setRoles] = useState([]);
    const [projectName, setProjectName] = useState('');
    const [projectId, setProjectId] = useState('');
    const [appName, setAppName] = useState('');
    const [appId, setAppId] = useState('');
    const [onboardAppName, setOnboardAppName] = useState('');
    const [onboardAppId, setOnboardAppId] = useState('');
    const [isSpinnerNeeded, setIsSpinnerNeeded] = useState(false);
    const [techRepoName, setTechRepoName] = useState('');
    const [selectedApp , setSelectedApp] = useState([])
    const [splashScreen , setSplashScreen] = useState(true)
    const [rowData, setRowData] = useState({});
    const [isGobalConfigLoading, setIsGlobalConfigLoading] = useState(false);

    return (
        <AppContext.Provider
            value={{
                showLoader,
                setShowLoader,
                // accessToken,
                // setAccessToken,
                // accessDetails,
                // setAccessDetails,
                // authorizedView,
                // setAuthorizedView,
                roles,
                setRoles,
                projectName,
                setProjectName,
                projectId,
                setProjectId,
                appName,
                setAppName,
                appId,
                setAppId,
                onboardAppName,
                setOnboardAppName,
                onboardAppId,
                setOnboardAppId,
                isSpinnerNeeded,
                setIsSpinnerNeeded,
                techRepoName,
                setTechRepoName,
                globalConfigData,
                setRowData,
                rowData,
                setGlobalConfigData,
                selectedApp,
                setSelectedApp,
                setSplashScreen,
                splashScreen,
                isGobalConfigLoading,
                setIsGlobalConfigLoading
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
