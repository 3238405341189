/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import StatusModal from '@components/modals/statusModal/StatusModal';
import { Routes } from '@constants/RoutingConstants';
import AddAppContext from '@context/addApp/AddAppContext';
import AddAppContextProvider from '@context/addApp/AddAppContextProvider';
import AppContext from '@context/app/AppContext';
import { errorImg, successStatusTick } from '@utils/ImageUtils';

import AddAppHeader from './AddAppHeader';
import AddBpm from './AddBpm';
import AddRepoForApp from './AddRepoForApp';
import AgileForm from './AgileForm';
// import AgileForm from './AgileForm';
import AppDetails from './AppDetails';
import ButtonCard from './ButtonCard';
import PreviewAndSubmit from './PreviewAndSubmit';
import Stepper from './Stepper';

import './newApp.css';

const AddAppComponent = ({ action }) => {
    const {
        currentPage,
        setCurrentPage,
        steps,
        editSteps,
        showDialog,
        successAddApp,
        setShowDialog,
        captureAddAppErr,
        setApplevelDetails,
        setCaptureBpmLevels,
        setRepTableData,
        setIsNextPageEnabled
    } = useContext(AddAppContext);

    const { rowData } = useContext(AppContext);

    const history = useHistory();

    useEffect(() => {
        if (action === 'Edit') {
            setApplevelDetails({
                name: rowData?.name,
                description: rowData?.description
            });
            setCaptureBpmLevels({
                businessDomain: rowData?.bpm?.businessDomain || '',
                businessKPIs: rowData?.bpm?.businessKPIs || '',
                itKPIs: rowData?.bpm?.itKPIs || '',
                bpmLevel1: rowData?.bpm?.l1BusinessProcess || '',
                bpmLevel2: rowData?.bpm?.l2BusinessProcess || '',
                bpmLevel3: rowData?.bpm?.l3BusinessProcess || '',
                bpmLevel4: ''
            });
            setRepTableData(
                Object.entries(rowData?.repositories || {}).map(
                    ([key, val]) => ({
                        repoName: key,
                        branchName: val.branch,
                        repoPath: val.url,
                        username: '',
                        pwd: ''
                    })
                )
            );
            setIsNextPageEnabled(true);
        }
    }, [Object.keys(rowData || {})?.length]);
    /**  comps will be rendered based on current page
     order not o be changed */
    const compList = {
        0: <AppDetails action={action} />,
        1: <AddBpm />,
        2: <AgileForm />,
        // 3: <AddRepoForApp />,
        3: <PreviewAndSubmit />
    };

    const handleBack = () => {
        if (action === 'Edit' && rowData?.name) {
            history.push({
                pathname: `${Routes.OnboardApps}/viewonboardapp/${rowData?.name}`,
                state: rowData
            });
        } else {
            history.push(Routes.OnboardApps);
        }
    };

    return (
        <div className="app-wrap">
            <div className="c-app-header">
                <div className="app-container">
                    <div className="c-app-header__wrapper">
                        <AddAppHeader
                            route={
                                action === 'Edit'
                                    ? `${Routes.OnboardApps}/viewonboardapp/${rowData?.name}`
                                    : Routes.OnboardApps
                            }
                            title={
                                action === 'Edit'
                                    ? 'Edit Application'
                                    : 'Add Application'
                            }
                            rowData={rowData}
                        />
                        <Stepper
                            steps={action === 'Edit' ? editSteps : steps}
                            currentStep={currentPage}
                            handleStep={setCurrentPage}
                        />
                    </div>
                </div>
            </div>
            <div className="c-index c-onboard">
                {compList[currentPage]}
                {showDialog && (
                    <StatusModal
                        text1={
                            successAddApp
                                ? 'Your Applications Onboarded Successfully.'
                                : captureAddAppErr
                        }
                        image={successAddApp ? successStatusTick : errorImg}
                        isModalOpen={showDialog}
                        closeModal={() => {
                            setShowDialog(false);
                            successAddApp && history.push(Routes.OnboardApps);
                        }}
                        altMsg={successAddApp ? 'Success' : 'Error'}
                    />
                )}
            </div>
            <ButtonCard
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                maxPage={steps.length}
                handleFallBackRoute={() => handleBack()}
            />
        </div>
    );
};

const AddApp = ({ action = 'Add' }) => {
    return (
        <AddAppContextProvider>
            <AddAppComponent action={action} />
        </AddAppContextProvider>
    );
};

export default AddApp;
