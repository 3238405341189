import React, { useContext, useEffect, useState } from 'react';
import CaptureFormError from '@components/ui/Forms/components/CaptureFormError';
import AddAppContext from '@context/addApp/AddAppContext';
import { validateInput } from '@utils/Util';

// import UploadFile from './UploadFile';

function AppDetails({ action }) {
    const {
        appLevelDetails,
        setApplevelDetails,
        setIsNextPageEnabled
    } = useContext(AddAppContext);

    const [inputErr, setInputErr] = useState({
        appName: [],
        appDesc: []
    });

    useEffect(() => {
        if (
            appLevelDetails.name.length > 0 &&
            appLevelDetails.description.length > 0 &&
            inputErr.appName.length <= 0 &&
            inputErr.appDesc.length <= 0
        )
            setIsNextPageEnabled(true);
        else setIsNextPageEnabled(false);
    }, [
        appLevelDetails.name,
        appLevelDetails.description,
        inputErr.appName,
        inputErr.appDesc
    ]);

    const inputStyles = {
        color: 'red',
        fontSize: 'smaller',
        float: 'right'
    };

    return (
        <div className="app-container">
            <h6 className="c-repo__title">
                Please provide the below application details
            </h6>
            <div className="c-repo__row -repo-form">
                <div className="c-repo__col add_app__input">
                    <div className="o-input">
                        <label className="-label" htmlFor="name">
                            Name<span style={inputStyles}>*</span>
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Enter Project name"
                            value={appLevelDetails.name}
                            disabled={action === 'Edit'}
                            id="name"
                            onChange={e => {
                                e.persist();
                                validateInput(
                                    e.target.value,
                                    'appName',
                                    setInputErr
                                );
                                setApplevelDetails(prev => ({
                                    ...prev,
                                    name: e.target.value
                                }));
                            }}
                        />
                        <CaptureFormError errors={inputErr.appName} />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                <div className="c-repo__col -full add_app__input">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="description">
                            Description<span style={inputStyles}>*</span>
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Enter Description"
                            value={appLevelDetails.description}
                            id="description"
                            onChange={e => {
                                e.persist();
                                validateInput(
                                    e.target.value,
                                    'appDesc',
                                    setInputErr
                                );

                                setApplevelDetails(prev => ({
                                    ...prev,
                                    description: e.target.value
                                }));
                            }}
                        />
                        <CaptureFormError errors={inputErr.appDesc} />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                {/* <div className="add_app__desc_error">
                    <CaptureFormError errors={inputErr.appDesc} />
                </div> */}
            </div>
            {/* <div className="c-repo__upload"> */}
            {/* <label className="-label">Attachments</label>
                <div className="c-dropfile-drag">
                    <img src={uploadFileBtn} alt="File Upload" />
                    <div className="c-dropfile-browse">
                        <div className="c-dropfile-browse__info">
                            Drag and drop files here or &nbsp;
                            <label className="c-dropfile-browse__link">
                                <span>Browse</span>
                                <input type="file" />
                            </label>
                        </div>
                        <span className="c-dropfile-browse__desc">
                            File should be Word, PDF
                        </span>
                    </div>
                </div>{' '} */}
            {/* {isFileDropped ? (
                    <div className="c-file">
                        <div className="c-file__header">Uploading File</div>
                        <div className="c-file__body">
                            <div className="c-file__content">
                                <div className="c-file__name">
                                    <img src={successTick} alt="success" />
                                    {fileName}
                                </div>
                                <span className="c-file__size">{fileSize}</span>
                                <a
                                    href="javascript:;"
                                    className="c-file__delete icon-delete"
                                    // onClick={clearUploadedData}
                                ></a>
                            </div>
                        </div>
                    </div>
                ) : (
                    <FileUpload
                        setFileName={setFileName}
                        setFileSize={setFileSize}
                        setIsFileDropped={setIsFileDropped}
                        setIsFileUploaded={setIsFileUploaded}
                        setData={setData}
                    />
                )} */}
            {/* <UploadFile /> */}
            {/* </div> */}
        </div>
    );
}

export default AppDetails;
