import React, { useContext, useEffect, useState } from 'react';
import CaptureFormError from '@components/ui/Forms/components/CaptureFormError';
import AddAppContext from '@context/addApp/AddAppContext';
import { validateInput } from '@utils/Util';

function AddBpm() {
    const [bpmErr, setBpmErr] = useState({
        businessDomain: [],
        businessKPIs: [],
        itKPIs: [],
        bpmLevel1: [],
        bpmLevel2: [],
        bpmLevel3: [],
        bpmLevel4: []
    });
    const {
        captureBpmLevels,
        setCaptureBpmLevels,
        isNextPageEnabled,
        setIsNextPageEnabled
    } = useContext(AddAppContext);

    useEffect(() => {
        if (
            captureBpmLevels &&
            captureBpmLevels.businessDomain.length > 0 &&
            captureBpmLevels.businessKPIs.length > 0 &&
            captureBpmLevels.itKPIs.length > 0 &&
            captureBpmLevels.bpmLevel1.length > 0 &&
            captureBpmLevels.bpmLevel2.length > 0 &&
            captureBpmLevels.bpmLevel3.length > 0 &&
            // Commenting bpmlevel 4 validation as it's not used in payload as of now
            // captureBpmLevels.bpmLevel4.length > 0 &&
            bpmErr.businessDomain.length <= 0 &&
            bpmErr.businessKPIs.length <= 0 &&
            bpmErr.itKPIs.length <= 0 &&
            bpmErr.bpmLevel1.length <= 0 &&
            bpmErr.bpmLevel2.length <= 0 &&
            bpmErr.bpmLevel3.length <= 0
            // && bpmErr.bpmLevel4.length <= 0
        )
            setIsNextPageEnabled(true);
        else setIsNextPageEnabled(false);
    }, [
        captureBpmLevels.businessDomain,
        captureBpmLevels.businessKPIs,
        captureBpmLevels.itKPIs,
        captureBpmLevels.bpmLevel1,
        captureBpmLevels.bpmLevel2,
        captureBpmLevels.bpmLevel3,
        captureBpmLevels.bpmLevel4,
        bpmErr.businessDomain,
        bpmErr.businessKPIs,
        bpmErr.itKPIs,
        bpmErr.bpmLevel1,
        bpmErr.bpmLevel2,
        bpmErr.bpmLevel3
        // bpmErr.bpmLevel4
    ]);

    console.log(captureBpmLevels, 'isNextPageEnabled');

    console.log(bpmErr, 'bpmErr');

    const inputStyles = {
        color: 'red',
        fontSize: 'smaller',
        float: 'right'
    };
    // checks validation on mount, when pages are switched to and fro
    useEffect(() => {
        if (captureBpmLevels.businessDomain.length > 0) {
            validateInput(
                captureBpmLevels.businessDomain,
                'businessDomain',
                setBpmErr
            );
        }
        if (captureBpmLevels.businessKPIs.length > 0) {
            validateInput(
                captureBpmLevels.businessKPIs,
                'businessKPIs',
                setBpmErr
            );
        }
        if (captureBpmLevels.itKPIs.length > 0) {
            validateInput(captureBpmLevels.itKPIs, 'itKPIs', setBpmErr);
        }
        if (captureBpmLevels.bpmLevel1.length > 0) {
            validateInput(captureBpmLevels.bpmLevel1, 'bpmLevel1', setBpmErr);
        }
        if (captureBpmLevels.bpmLevel2.length > 0) {
            validateInput(captureBpmLevels.bpmLevel2, 'bpmLevel2', setBpmErr);
        }
        if (captureBpmLevels.bpmLevel3.length > 0) {
            validateInput(captureBpmLevels.bpmLevel3, 'bpmLevel3', setBpmErr);
        }
    }, []);

    return (
        <div className="app-container">
            <h6 className="c-repo__title">Add BPM level for the application</h6>
            <div className="c-repo__row">
                <div className="c-repo__col add_app__input">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="bsnsdmn">
                            Business Domain<span style={inputStyles}>*</span>
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Enter Business Domain"
                            id="bsnsdmn"
                            value={captureBpmLevels.businessDomain}
                            onChange={e => {
                                e.persist();

                                validateInput(
                                    e.target.value,
                                    'businessDomain',
                                    setBpmErr
                                );
                                setCaptureBpmLevels(prev => ({
                                    ...prev,
                                    businessDomain: e.target.value
                                }));
                            }}
                        />
                        <CaptureFormError errors={bpmErr.businessDomain} />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                <div className="c-repo__col add_app__input">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="bsnskpi">
                            Business KPIs<span style={inputStyles}>*</span>
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Enter Business KPIs"
                            id="bsnskpi"
                            value={captureBpmLevels.businessKPIs}
                            onChange={e => {
                                e.persist();

                                validateInput(
                                    e.target.value,
                                    'businessKPIs',
                                    setBpmErr
                                );
                                setCaptureBpmLevels(prev => ({
                                    ...prev,
                                    businessKPIs: e.target.value
                                }));
                            }}
                        />
                        <CaptureFormError errors={bpmErr.businessKPIs} />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                <div className="c-repo__col add_app__input">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="itkpi">
                            IT KPIs<span style={inputStyles}>*</span>
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Enter IT KPIs"
                            id="itkpi"
                            value={captureBpmLevels.itKPIs}
                            onChange={e => {
                                e.persist();

                                validateInput(
                                    e.target.value,
                                    'itKPIs',
                                    setBpmErr
                                );
                                setCaptureBpmLevels(prev => ({
                                    ...prev,
                                    itKPIs: e.target.value
                                }));
                            }}
                        />
                        <CaptureFormError errors={bpmErr.itKPIs} />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                <div className="c-repo__col add_app__input">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="lev1">
                            BPM Level 1<span style={inputStyles}>*</span>
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Enter level"
                            id="lev1"
                            value={captureBpmLevels.bpmLevel1}
                            onChange={e => {
                                e.persist();

                                validateInput(
                                    e.target.value,
                                    'bpmLevel1',
                                    setBpmErr
                                );
                                setCaptureBpmLevels(prev => ({
                                    ...prev,
                                    bpmLevel1: e.target.value
                                }));
                            }}
                        />
                        <CaptureFormError errors={bpmErr.bpmLevel1} />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                <div
                    className="c-repo__col add_app__input"
                    style={{ marginTop: '40px' }}
                >
                    <div className="o-input     ">
                        <label className="-label" htmlFor="lev2">
                            BPM Level 2<span style={inputStyles}>*</span>
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Enter level"
                            value={captureBpmLevels.bpmLevel2}
                            id="lev2"
                            onChange={e => {
                                e.persist();

                                validateInput(
                                    e.target.value,
                                    'bpmLevel2',
                                    setBpmErr
                                );
                                setCaptureBpmLevels(prev => ({
                                    ...prev,
                                    bpmLevel2: e.target.value
                                }));
                            }}
                        />
                        <CaptureFormError errors={bpmErr.bpmLevel2} />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                <div className="c-repo__col add_app__input">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="lev3">
                            BPM Level 3<span style={inputStyles}>*</span>
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Enter level"
                            value={captureBpmLevels.bpmLevel3}
                            id="lev3"
                            onChange={e => {
                                e.persist();
                                validateInput(
                                    e.target.value,
                                    'bpmLevel3',
                                    setBpmErr
                                );
                                setCaptureBpmLevels(prev => ({
                                    ...prev,
                                    bpmLevel3: e.target.value
                                }));
                            }}
                        />
                        <CaptureFormError errors={bpmErr.bpmLevel3} />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBpm;
