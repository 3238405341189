import React from 'react';

function Pagination({ pageNumbers, currentPage, setCurrentPage, data }) {
    const changePageNumber = key => {
        if (key === 'previous' && currentPage !== 1) {
            setCurrentPage(page => parseInt(page, 10) - 1);
        } else if (key === 'next' && currentPage !== pageNumbers) {
            setCurrentPage(page => parseInt(page, 10) + 1);
        } else {
            setCurrentPage(key);
        }
    };

    return (
        <div>
            <div>
                {data && Array.isArray(data) ? (
                    <>Total Results: {data.length} </>
                ) : (
                    ''
                )}
            </div>
            <div>
                <nav>
                    <ul>
                        <li>
                            <button
                                type="button"
                                className="page-link"
                                aria-label="Previous"
                                tabIndex="-1"
                                aria-disabled="true"
                                onClick={() => changePageNumber('previous')}
                            >
                                <span aria-hidden="true">&lt;</span>
                            </button>
                        </li>
                        <li aria-current="page">
                            <input
                                type="text"
                                className="form-control"
                                value={currentPage}
                                onChange={e => changePageNumber(e.target.value)}
                            />
                        </li>
                        <li>
                            <span>Of {pageNumbers}</span>
                        </li>
                        <li>
                            <button
                                type="button"
                                className="page-link"
                                aria-label="Next"
                                onClick={() => changePageNumber('next')}
                            >
                                <span aria-hidden="true">&gt;</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}
export default Pagination;
