import React from 'react';

const SelectCardFooter = ({
    optionalText,
    primaryBtnLabel,
    secondaryBtnLabel,
    setIsPrimaryBtnClicked,
    handlePrimaryBtnClick,
    handleSecondaryBtnClick,
    isPrimaryBtnDisabled,
    isSecondaryBtnDisabled,
    fromScanAppRepo
}) => {
    const handlePrimaryButton = () => {
        if (fromScanAppRepo) {
            setIsPrimaryBtnClicked(true);
        } else {
            handlePrimaryBtnClick();
        }
    };
    return (
        <>
            <div className="c-select-card">
                <div className="app-container">
                    <div className="c-select-card__wrapper">
                        {optionalText ? (
                            <div className="c-select-card__left">
                                <div className="c-repo-selected">
                                    <div className="-text">
                                        {/* <span>4</span> repository selected for scan */}
                                        <span>{optionalText}</span> repository
                                        selected for scan
                                    </div>
                                    {/* <a
                                        href="javascript:;"
                                        role="button"
                                        title="close"
                                        className="icon-cross"
                                    ></a> */}
                                </div>
                            </div>
                        ) : null}
                        <div className="c-select-card__right">
                            <a
                                href="javascript:void(0)"
                                role="button"
                                className={`o-secondary-button ${
                                    isSecondaryBtnDisabled ? '-disabled' : ''
                                }`}
                                onClick={handleSecondaryBtnClick}
                            >
                                {secondaryBtnLabel}
                            </a>
                            <a
                                href="javascript:void(0)"
                                role="button"
                                className={`o-primary-button ${
                                    isPrimaryBtnDisabled ? '-disabled' : ''
                                }`}
                                onClick={() => handlePrimaryButton()}
                            >
                                {primaryBtnLabel}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="c-select-card__placeholder"></div>
        </>
    );
};

export default SelectCardFooter;
