/* eslint-disable consistent-return */
import React from 'react';
import { Link } from 'react-router-dom';
import BpmLevelChip from '@components/chip/BpmLevelChip';
import { Routes } from '@constants/RoutingConstants';

// import GenwizardTable from '../GenwizardTable';

function ColumnConstants(tableName, performChildAction, parentRowData) {
    const OnboardPageTable = {
        // colspan: ['20%', '20%', '25%', '20%', '15%'],
        columns: [
            {
                title: 'Application Name',
                scope: 'col',
                width: '15%',
                render: rowData => {
                    return (
                        <Link
                            to={{
                                pathname: `${Routes.OnboardApps}/viewonboardapp/${rowData.name}`,
                                state: rowData
                            }}
                            className="c-table-link"
                        >
                            {rowData.name}
                        </Link>
                    );
                }
            },
            {
                title: 'Description',
                scope: 'col',
                width: '23%',
                render: rowData => {
                    return (
                        <div
                            className="-tooltip"
                            style={{ wordWrap: 'break-word' }}
                            // data-myW-title={rowData.path}
                            // data-myW-toggle="tooltip"
                            // data-myW-placement="bottom"
                            // data-myW-custClass="-testTooltip"
                        >
                            {rowData.description}
                        </div>
                    );
                }
            },
            {
                title: 'Repository',
                scope: 'col',
                width: '7%',
                render: rowData => {
                    return rowData.repo_count;
                }
            },
            {
                title: 'BPM Level',
                scope: 'col',
                width: '20%',
                render: rowData => {
                    return (
                        <BpmLevelChip levels={rowData.bpm} shouldBeExpanded />
                    );
                }
            },
            // Disabling Agile flow
            {
                title: 'Agile',
                childCols: [
                    {
                        property: 'AgileToolEndpoint',
                        label: 'Tool Endpoint'
                    },
                    {
                        property: 'AgileToolType',
                        label: 'Tool Type'
                    },
                    {
                        property: 'ExistingAgileProjectName',
                        label: 'Project Name'
                    }
                ],
                colSpan: '3',
                width: '35%',
                childWidth: '11.6%',
                className: 'c-text-center',
                childClassName: 'c-text-center',
                tooltipTitle: 'File Scan',
                scope: 'col',
                render: (rowData, selection) => {
                    const { agile_details: agileDetails } = rowData;
                    return agileDetails?.[selection] || '';
                }
            }
        ]
    };

    const AddRepoTable = {
        columns: [
            {
                title: 'Repository Name',
                scope: 'col',
                width: '25%',
                render: rowData => {
                    return rowData.repoName;
                }
            },
            {
                title: 'Path',
                scope: 'col',
                width: '33%',
                render: rowData => {
                    return rowData.repoUrl;
                }
            },

            {
                title: 'Module',
                scope: 'col',
                width: '30%',
                render: rowData => {
                    return rowData.module;
                }
            },
            {
                title: 'File Type',
                scope: 'col',
                width: '12%',
                render: rowData => {
                    return rowData.type;
                }
            }
        ]
    };
    const AddAppRepoTable = {
        columns: [
            {
                title: 'Repository Name',
                scope: 'col',
                width: '24%',
                render: rowData => {
                    return rowData.repoName;
                }
            },
            {
                title: 'Path',
                scope: 'col',
                width: '49%',
                render: rowData => {
                    return rowData.repoPath;
                }
            },
            {
                title: 'Branch',
                scope: 'col',
                width: '12%',
                render: rowData => {
                    return rowData.branchName;
                }
            },

            {
                title: 'Action',
                scope: 'col',
                width: '15%',
                render: rowData => {
                    return (
                        <a
                            href="javascript:;"
                            className="c-table-link"
                            onClick={() => {
                                performChildAction('removeRepo', rowData);
                            }}
                        >
                            Remove
                        </a>
                    );
                }
            }
        ]
    };

    const ImportAppPageTable = {
        columns: [
            {
                title: 'Application Name',
                scope: 'col',
                width: '20%',
                render: rowData => {
                    return rowData.appName;
                }
            },
            {
                title: 'Description',
                scope: 'col',
                width: '30%',
                render: rowData => {
                    return (
                        <div
                            className="c-text-wrap -tooltip"
                            // data-myW-title={rowData.path}
                            // data-myW-toggle="tooltip"
                            // data-myW-placement="bottom"
                            // data-myW-custClass="-testTooltip"
                        >
                            {rowData.description}
                        </div>
                    );
                }
            },
            {
                title: 'BPM Level',
                scope: 'col',
                width: '30%',
                render: rowData => {
                    const bpm = {};
                    bpm.businessDomain = rowData.businessDomain;
                    bpm.l1BusinessProcess = rowData.l1BusinessProcess;
                    bpm.l2BusinessProcess = rowData.l2BusinessProcess;
                    bpm.l3BusinessProcess = rowData.l3BusinessProcess;
                    bpm.businessKPIs = rowData.businessKPIs;
                    bpm.itKPIs = rowData.itKPIs;
                    return <BpmLevelChip levels={bpm} />;
                }
            },
            {
                title: 'Action',
                scope: 'col',
                width: '20%',
                render: rowData => {
                    return (
                        <a
                            href="javascript:;"
                            className="c-table-link"
                            onClick={() => {
                                performChildAction('removeRow', rowData);
                            }}
                        >
                            Remove
                        </a>
                    );
                }
            }
        ]
    };

    switch (tableName) {
        case 'onboardPageTable':
            return OnboardPageTable;
        case 'addRepoTable':
            return AddRepoTable;
        case 'addAppRepoTable':
            return AddAppRepoTable;
        case 'importAppPageTable':
            return ImportAppPageTable;
        default:
            break;
    }
}

export default ColumnConstants;
