import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '@constants/RoutingConstants';
import { AiOutlineHome } from 'react-icons/ai';
import Tippy from '@tippyjs/react';


const Breadcrumbs = () => {
  return (
    <div style={{
        display:"flex",
        padding:"0.75rem 4.75rem",
        borderBottom: '0.65px solid #ebebeb',
        alignItems:"center"
    }}>
        <Tippy content="App Selection" placement="bottom">
            <Link to={Routes.SplashScreen}>
                <AiOutlineHome
                    style={{
                        width: '17px',
                        height: '15px'
                    }}
                />
            </Link>
        </Tippy>
        {sessionStorage.getItem("AppName") != undefined && <>
            <span style={{marginLeft:"5px"}}> / </span>
            <Tippy content="Manage" placement="bottom">
                <Link to={Routes.Manage}>
                    <span style={{marginLeft:"5px"}}> Manage </span>
                </Link>
            </Tippy>
        </>}
    </div>
  )
}

export default Breadcrumbs