import React from 'react';
import { loaderImg } from '@utils/ImageUtils';

import './GenwizardLoader.css';

const GenwizardLoader = () => {
    return (
        <div className="genwizard_loader__top__head">
            <div className="genwizard__loader">
                <img src={loaderImg} alt="loading" />
            </div>
        </div>
    );
};

export default GenwizardLoader;
