import Keycloak from 'keycloak-js';

let keycloak = null;

function useKeycloak(realm = process.env.REACT_APP_DEFAULT_REALM) {
    const hasResourceRole = role => {
        return keycloak.hasResourceRole(role, 'insights');
    };
    if (!keycloak) {
        const keycloakConf = {
            url: `${process.env.REACT_APP_PLATFORM_URL}/auth`,
            realm,
            clientId: 'acpui'
        };
        keycloak = Keycloak(keycloakConf);
    }
    return { keycloak, realm: keycloak.realm, hasResourceRole };
}

export default useKeycloak;
