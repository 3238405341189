/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

import CONSTANTS from './Constants/ValidationConstants';

const scriptTestName = 'malicious-script-validation';
const scriptTestMessage = 'Script tag, < or > are not allowed';
export const validateScriptTag = value => {
    return !(
        value.toLowerCase().includes('<script>') ||
        value.toLowerCase().includes('</script>')
    );
};

export const addAppNameValidate = Yup.object({
    appName: Yup.string()
        .required(CONSTANTS.addApp.appName.required.message)
        .min(
            CONSTANTS.addApp.appName.min.length,
            CONSTANTS.addApp.appName.min.message
        )
        .max(
            CONSTANTS.addApp.appName.max.length,
            CONSTANTS.addApp.appName.max.message
        )
        .matches(
            CONSTANTS.addApp.appName.match.criteria,
            CONSTANTS.addApp.appName.match.message
        )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});
export const addAppDescValidate = Yup.object({
    appDesc: Yup.string()
        .required(CONSTANTS.addApp.appDesc.required.message)
        .min(
            CONSTANTS.addApp.appDesc.min.length,
            CONSTANTS.addApp.appDesc.min.message
        )
        .max(
            CONSTANTS.addApp.appDesc.max.length,
            CONSTANTS.addApp.appDesc.max.message
        )
        .matches(
            CONSTANTS.addApp.appDesc.match.criteria,
            CONSTANTS.addApp.appDesc.match.message
        )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});

export const businessDomainValidate = Yup.object({
    businessDomain: Yup.string()
        .required(CONSTANTS.addApp.businessDomain.required.message)
        .min(
            CONSTANTS.addApp.businessDomain.min.length,
            CONSTANTS.addApp.businessDomain.min.message
        )
        .max(
            CONSTANTS.addApp.businessDomain.max.length,
            CONSTANTS.addApp.businessDomain.max.message
        )
        .matches(
            CONSTANTS.addApp.businessDomain.match.criteria,
            CONSTANTS.addApp.businessDomain.match.message
        )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});

export const businessKPIsValidate = Yup.object({
    businessKPIs: Yup.string()
        .required(CONSTANTS.addApp.businessKPIs.required.message)
        .min(
            CONSTANTS.addApp.businessKPIs.min.length,
            CONSTANTS.addApp.businessKPIs.min.message
        )
        .max(
            CONSTANTS.addApp.businessKPIs.max.length,
            CONSTANTS.addApp.businessKPIs.max.message
        )
        .matches(
            CONSTANTS.addApp.businessKPIs.match.criteria,
            CONSTANTS.addApp.businessKPIs.match.message
        )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});

export const itKPIsValidate = Yup.object({
    itKPIs: Yup.string()
        .required(CONSTANTS.addApp.itKPIs.required.message)
        .min(
            CONSTANTS.addApp.itKPIs.min.length,
            CONSTANTS.addApp.itKPIs.min.message
        )
        .max(
            CONSTANTS.addApp.itKPIs.max.length,
            CONSTANTS.addApp.itKPIs.max.message
        )
        .matches(
            CONSTANTS.addApp.itKPIs.match.criteria,
            CONSTANTS.addApp.itKPIs.match.message
        )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});

export const bpmLevelOneValidate = Yup.object({
    bpmLevel1: Yup.string()
        .required(CONSTANTS.addApp.bpmLevel1.required.message)
        .min(
            CONSTANTS.addApp.bpmLevel1.min.length,
            CONSTANTS.addApp.bpmLevel1.min.message
        )
        .max(
            CONSTANTS.addApp.bpmLevel1.max.length,
            CONSTANTS.addApp.bpmLevel1.max.message
        )
        .matches(
            CONSTANTS.addApp.bpmLevel1.match.criteria,
            CONSTANTS.addApp.bpmLevel1.match.message
        )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});
export const bpmLevelTwoValidate = Yup.object({
    bpmLevel2: Yup.string()
        .required(CONSTANTS.addApp.bpmLevel2.required.message)
        .min(
            CONSTANTS.addApp.bpmLevel2.min.length,
            CONSTANTS.addApp.bpmLevel2.min.message
        )
        .max(
            CONSTANTS.addApp.bpmLevel2.max.length,
            CONSTANTS.addApp.bpmLevel2.max.message
        )
        .matches(
            CONSTANTS.addApp.bpmLevel2.match.criteria,
            CONSTANTS.addApp.bpmLevel2.match.message
        )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});
export const bpmLevelThreeValidate = Yup.object({
    bpmLevel3: Yup.string()
        .required(CONSTANTS.addApp.bpmLevel3.required.message)
        .min(
            CONSTANTS.addApp.bpmLevel3.min.length,
            CONSTANTS.addApp.bpmLevel3.min.message
        )
        .max(
            CONSTANTS.addApp.bpmLevel3.max.length,
            CONSTANTS.addApp.bpmLevel3.max.message
        )
        .matches(
            CONSTANTS.addApp.bpmLevel3.match.criteria,
            CONSTANTS.addApp.bpmLevel3.match.message
        )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});
export const bpmLevelFourValidate = Yup.object({
    bpmLevel4: Yup.string()
        .required(CONSTANTS.addApp.bpmLevel4.required.message)
        .min(
            CONSTANTS.addApp.bpmLevel4.min.length,
            CONSTANTS.addApp.bpmLevel4.min.message
        )
        .max(
            CONSTANTS.addApp.bpmLevel4.max.length,
            CONSTANTS.addApp.bpmLevel4.max.message
        )
        .matches(
            CONSTANTS.addApp.bpmLevel4.match.criteria,
            CONSTANTS.addApp.bpmLevel4.match.message
        )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});

export const addAppRepoValidate = Yup.object({
    repoName: Yup.string()
        .required(CONSTANTS.addRepo.repoName.required.message)
        .min(
            CONSTANTS.addRepo.repoName.min.length,
            CONSTANTS.addRepo.repoName.min.message
        )
        .max(
            CONSTANTS.addRepo.repoName.max.length,
            CONSTANTS.addRepo.repoName.max.message
        )
        .matches(
            CONSTANTS.addRepo.repoName.match.criteria,
            CONSTANTS.addRepo.repoName.match.message
        )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});
export const addAppPathValidate = Yup.object({
    repoPath: Yup.string()
        .url(CONSTANTS.addRepo.repoPath.match.message)
        .required(CONSTANTS.addRepo.repoPath.required.message)
        .min(
            CONSTANTS.addRepo.repoPath.min.length,
            CONSTANTS.addRepo.repoPath.min.message
        )
        .max(
            CONSTANTS.addRepo.repoPath.max.length,
            CONSTANTS.addRepo.repoPath.max.message
        )
        // .matches(
        //     CONSTANTS.addRepo.repoPath.match.criteria,
        //     CONSTANTS.addRepo.repoPath.match.message
        // )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});
export const addAppBranchNameValidate = Yup.object({
    branchName: Yup.string()
        .required(CONSTANTS.addRepo.branchName.required.message)
        .min(
            CONSTANTS.addRepo.branchName.min.length,
            CONSTANTS.addRepo.branchName.min.message
        )
        .max(
            CONSTANTS.addRepo.branchName.max.length,
            CONSTANTS.addRepo.branchName.max.message
        )
        .matches(
            CONSTANTS.addRepo.branchName.match.criteria,
            CONSTANTS.addRepo.branchName.match.message
        )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});

export const addAppUserNameValidate = Yup.object({
    username: Yup.string()
        .required(CONSTANTS.addRepo.username.required.message)
        .min(
            CONSTANTS.addRepo.username.min.length,
            CONSTANTS.addRepo.username.min.message
        )
        .max(
            CONSTANTS.addRepo.username.max.length,
            CONSTANTS.addRepo.username.max.message
        )
        .matches(
            CONSTANTS.addRepo.username.match.criteria,
            CONSTANTS.addRepo.username.match.message
        )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});
export const addAppPasswordValidate = Yup.object({
    pass: Yup.string()
        .required(CONSTANTS.addRepo.pass.required.message)
        .min(
            CONSTANTS.addRepo.pass.min.length,
            CONSTANTS.addRepo.pass.min.message
        )
        .max(
            CONSTANTS.addRepo.pass.max.length,
            CONSTANTS.addRepo.pass.max.message
        )
        .test(scriptTestName, scriptTestMessage, value => {
            return validateScriptTag(value);
        })
});

export const validationFunction = (
    validationSchema,
    values,
    initialErrorValues
) => {
    console.log({ validationSchema, values, initialErrorValues }, 'sdsdsd');
    return validationSchema
        .validate(values, { abortEarly: false })
        .then(() => {})
        .catch(err => {
            const FIRST_ERROR = 0;

            return err.inner.reduce((errors, currentError) => {
                return {
                    ...errors,
                    [currentError.path]: [
                        ...errors[currentError.path],
                        currentError.errors[FIRST_ERROR]
                    ]
                };
            }, initialErrorValues);
        });
};
