/* eslint-disable import/prefer-default-export */
import React, { useContext, useEffect, useState } from 'react';

import './ReleaseInfo.css';
import { getLazyQuery } from '@utils/Util';
import { GET_GLOBAL_CONFIG } from '@graphql/queries';
import AppContext from '@context/app/AppContext';
import LoaderBody from '@components/table/component/LoaderBody';

export const ReleaseInfo = () => {
    const [selectedOption, setSelectedOption] = useState('Release 1.0');

    const { globalConfigData, isGobalConfigLoading } = useContext(AppContext);

    const handleSelectChange = event => {
        setSelectedOption(event.target.value);
    };

    // derived state

    const releaseData =
        globalConfigData?.data?.find(item => item.name === 'release_notes')
            ?.value || {};
    const style = {
        marginLeft: '1.5rem',
        marginTop: '-0.875rem',
        lineHeight: '24px'
    };
    const indistyle = {
        marginTop: '-0.30rem',
        lineHeight: '24px'
    };

    return (
        <div className="app-wrap-release">
            {isGobalConfigLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '70vh'
                    }}
                >
                    <LoaderBody />
                </div>
            ) : (
                <div className="app-container">
                    <div className=".c-release-note">
                        <div className="c-select-dropdown">
                            <h3>Release Notes</h3>
                            <select
                                className="select-box"
                                name="versions"
                                id="versions"
                                value={selectedOption}
                                onChange={handleSelectChange}
                            >
                                {Object.keys(releaseData).map(
                                    (release, index) => (
                                        <option key={index} value={release}>
                                            {release}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                        <div className="c-release__overview">
                            <h3
                                style={{
                                    borderBottom: '1px solid #ccc',
                                    paddingBottom: '0.625rem'
                                }}
                            >
                                Overview
                            </h3>
                            <p className="-desc">
                                {releaseData[selectedOption]?.overview}
                            </p>
                            <div className="c-release-whats-new">
                                <h3>Features and Enhancements</h3>
                            </div>
                            {releaseData[
                                selectedOption
                            ]?.features_and_enhancements.map(
                                (section, index) => (
                                    <>
                                        {console.log(section, 'sectionz')}
                                        <div
                                            className="c-release-module"
                                            style={{
                                                textDecoration: 'underline'
                                            }}
                                        >
                                            {section?.title}
                                        </div>
                                        <ul className="c-release-list">
                                            {section.items.map((item, idx) => {
                                                console.log(item, 'dskhdskh');
                                                if (item.title) {
                                                    return (
                                                        <>
                                                            <li key={idx}>
                                                                {item.title && (
                                                                    <b>
                                                                        {
                                                                            item.title
                                                                        }
                                                                    </b>
                                                                )}
                                                            </li>
                                                            <p style={style}>
                                                                {
                                                                    item.description
                                                                }
                                                            </p>
                                                        </>
                                                    );
                                                }
                                                return (
                                                    <>
                                                        <li key={idx}>
                                                            {item.title && (
                                                                <b>
                                                                    {item.title}
                                                                </b>
                                                            )}
                                                            <p
                                                                style={
                                                                    indistyle
                                                                }
                                                            >
                                                                {
                                                                    item.description
                                                                }
                                                            </p>
                                                        </li>
                                                    </>
                                                );
                                            })}
                                        </ul>
                                    </>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};