import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getLazyQuery } from '@utils/Util';
import { Routes } from '@constants/RoutingConstants';

import { ONBOARD_APP_LIST } from '@graphql/queries';
import Loader from '@components/ui/loader/Loader';

const SplashScreen = () => {
    const history = useHistory();
    const [
        fetchAppsList,
        { data: appsList, loading: isAppsListLoading, error: appsListError }
    ] = getLazyQuery(ONBOARD_APP_LIST, {
        orgName: sessionStorage.getItem("ORG_NAME")
    });

    useEffect(() => {
        sessionStorage.getItem("ORG_NAME") && fetchAppsList()
    }, [])

    const handleSelection = (appName) => {
        sessionStorage.setItem('AppName', appName);
        history.push("/manage");
    };

    console.log(appsList, 'applist')
    const [hovered, setHovered] = useState(false);
    return (
        <div className="app-container" style={{display:"flex",height:"100%",width:"100%",justifyContent:"center"}}>
            <div style={{display:"flex",height:"100%",width:"100%",flexDirection:"column",alignItems:"center"}}>
                <h6 className="c-manage__title" style={{ fontSize: '1.3rem', marginRight: '4%', marginBottom: '45px'}}>
                    Select the Application
                </h6>
                {isAppsListLoading ?
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '70vh'
                        }}
                    >
                        <Loader />
                    </div> :
                    <div style={{
                        display:"grid",
                        gridTemplateColumns: "25% 25% 25% 25%",
                        gridGap: "20px 20px"
                    }}>
                        {
                            appsList?.getAppList?.map((item) => {
                                return (
                                    <div style={{
                                        display:"flex",
                                        position:"relative",
                                        height:"100px",
                                        width:"300px",
                                        boxShadow: "0 0.125rem 0.625rem rgba(0, 0, 0, 0.1)",
                                        borderRadius: "8px",
                                        backgroundColor: "#fff",
                                        justifyContent:"center",
                                        alignItems:"center",
                                        marginBottom:"10px",
                                        cursor:"pointer",
                                        
                                    }}
                                    className='c-manage-card'
                                    >
                                        <a
                                            href="javascript:;"
                                            className="c-manage-card__header -stretch-link"
                                            style={{zIndex:99}}
                                            onClick={() => handleSelection(item.name)}
                                            onMouseOver={()=>{setHovered((prev)=>!prev)}}
                                        >
                                            {item.name}
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>

    )
};

export default SplashScreen;