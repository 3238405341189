import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '@constants/RoutingConstants';
import { backArrow } from '@utils/ImageUtils';

const AppHeader = ({ title }) => {
    return (
        <div className="c-app-header">
            <div className="app-container">
                <div className="c-app-header__wrapper">
                    <div className="c-app-header__title">
                        <Link to={Routes.OnboardApps}>
                            <img
                                src={backArrow}
                                alt="Back"
                                role="presentation"
                            />
                        </Link>
                        {title}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppHeader;
