import React from 'react';
import { Link } from 'react-router-dom';
import { backArrow } from '@utils/ImageUtils';
import PropTypes from 'prop-types';

function AddAppHeader({ route, title , rowData}) {
    return (
        <div className="c-app-header__title">
            <Link to={{
                pathname : route,
                state: rowData
                }}>
                <img src={backArrow} alt="Back" role="presentation" />
            </Link>
            {title}
        </div>
    );
}
AddAppHeader.propTypes = {
    route: PropTypes.string,
    title: PropTypes.string
};

AddAppHeader.defaultProps = {
    route: '',
    title: ''
};
export default AddAppHeader;
