import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Routes } from '@constants/RoutingConstants';
import AddApp from '@containers/onboard/newApp/AddApp';
import Onboard from '@containers/onboard/Onboard';
import OnboardApp from '@containers/onboard/onboardApp/OnboardApp';
import ViewOnboardApp from '@containers/onboard/viewApp/ViewOnboardApp';
import FilterContextProvider from '@context/filters/FilterContextProvider';

const OnboardRouteContext = () => {
    return (
        <Switch>
            <Route exact path={Routes.Onboard}>
                <Redirect to={Routes.OnboardApps} />
            </Route>
            <Route exact path={Routes.OnboardApps} component={Onboard} />
            <Route
                exact
                path={Routes.ViewOnboardApp}
                component={ViewOnboardApp}
            />
            <Route exact path={Routes.NewApp} component={AddApp} />
            <Route exact path={Routes.OnboardNewApp} component={OnboardApp} />
        </Switch>
    );
};

const OnboardRoute = () => {
    return (
        <FilterContextProvider>
            <OnboardRouteContext />
        </FilterContextProvider>
    );
};

export default OnboardRoute;
