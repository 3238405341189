import React from 'react';

const RadioBtn = ({
    item,
    groupName,
    selectedRadioBtn,
    setSelectedRadioBtn
}) => {
    return (
        <div className="o-radio">
            <input
                type="radio"
                id={item.id}
                className="o-radio__input"
                name={groupName}
                checked={selectedRadioBtn === item.id}
                onChange={() => setSelectedRadioBtn(item.id)}
            />
            <i className="o-radio__checked"></i>
            <label htmlFor={item.id} className="o-radio__label">
                {item.label}
            </label>
        </div>
    );
};

export default RadioBtn;
