import React from 'react';

const StatusModal = ({
    text1,
    image,
    isModalOpen,
    closeModal,
    altMsg,
    providePlainErr = false,
    status = ''
}) => {
    return (
        <div
            id="runScanModal"
            className="c-modal c-run-scan__modal"
            tabIndex="-1"
            // aria-hidden="true"
            aria-hidden={!isModalOpen}
            aria-modal={isModalOpen}
            role={isModalOpen && 'dialog'}
            aria-labelledby="modalDialogTitle"
        >
            <div className="c-modal__overlay -dismiss-modal"></div>
            <div className="c-modal__container">
                <div className="c-modal__content">
                    <div className="c-modal__header">
                        <button
                            type="button"
                            className="c-modal__header-close -dismiss-modal"
                            aria-label="Close"
                            title="Close"
                            onClick={() => closeModal()}
                        ></button>
                    </div>
                    <div className="c-modal__body">
                        {providePlainErr ? (
                            <div className="c-run-scan__content">
                                <img src={image} alt="error msg" />
                                <div className="c-run-scan__req">
                                    <div
                                        style={
                                            status === 'success'
                                                ? {}
                                                : {
                                                      color: 'red'
                                                  }
                                        }
                                    >
                                        {text1}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="c-run-scan__content">
                                <img src={image} alt={altMsg} />
                                <div className="c-run-scan__req">
                                    {text1.includes('scan') ? (
                                        <>
                                            <span>{text1.slice(0, 26)}</span>
                                            <span>{text1.slice(27, 61)}</span>
                                            <div>
                                                {text1.slice(62, 91)} &nbsp;
                                                <a
                                                    href="javascript:;"
                                                    style={{
                                                        pointerEvents: 'none'
                                                    }}
                                                >
                                                    {text1.slice(92, 103)}
                                                </a>{' '}
                                                &nbsp; {text1.slice(103, 115)}
                                            </div>
                                        </>
                                    ) : (
                                        <span>{text1}</span>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="c-modal__footer">
                        <div className="c-modal__footer-btnGroup">
                            <a
                                href="javascript:void(0)"
                                role="button"
                                className="o-primary-button -dismiss-modal"
                                onClick={() => closeModal()}
                            >
                                OK
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatusModal;
