import React from 'react';

const Row = React.forwardRef(
    (
        { isClickable, index, item, isLastRow, columns, onRowClick, ...rest },
        ref
    ) => {
        const HandleClickableRow = event => {
            if (
                event.target.alt !== 'Run icon' &&
                event.target.alt !== 'Report icon' &&
                event.target.alt !== 'Edit icon' &&
                event.target.alt !== 'Delete icon' &&
                event.target.alt !== 'Three dot' &&
                event.target.alt !== 'Comment icon' &&
                event.target.nodeName !== 'A' &&
                event.target.nodeName !== 'BUTTON' &&
                event.target.className !== 'underline-on-hover' &&
                event.target.className !== 'do-not-expand-on-click'
            ) {
                if (isClickable) onRowClick(item, 'Handletoggle');
            }
        };

        return (
            <tr
                className={isClickable ? 'custom-tr' : ''}
                key={index}
                onClick={event => {
                    HandleClickableRow(event);
                }}
                style={
                    item.type === 'header'
                        ? { backgroundColor: '#A8A8A8' }
                        : item.type === 'overall'
                        ? { backgroundColor: '#D6EAF8' }
                        : isClickable
                        ? { cursor: 'pointer' }
                        : {}
                }
                ref={ref || null}
                {...rest}
            >
                {columns.map((col, key) => {
                    return (
                        <>
                            {col?.childCols?.length > 0 ? (
                                col.childCols.map(
                                    (childColItem, childColIndex) => {
                                        return (
                                            <td
                                                key={childColIndex}
                                                width={
                                                    col.childWidth || col.width
                                                }
                                                className={
                                                    col.childClassName ||
                                                    col.className
                                                }
                                            >
                                                {col.render(
                                                    item,
                                                    childColItem.property
                                                )}
                                            </td>
                                        );
                                    }
                                )
                            ) : (
                                <td
                                    key={key}
                                    width={col.width || ''}
                                    className={col.className || ''}
                                >
                                    {col.render(item, index, isLastRow)}
                                </td>
                            )}
                        </>
                    );
                })}
            </tr>
        );
    }
);

export default Row;
