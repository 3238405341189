export const BLUE = '#7188DC';
export const GREEN = '#67CABD';
export const ORANGE = '#DCDC67';
export const PURPLE = '#9657D5';

export const LIGHT_SHADE1 = '#8FD399';
export const LIGHT_SHADE2 = '#B7DC75';
export const LIGHT_SHADE3 = '#DCDC67';
export const LIGHT_SHADE4 = '#DCC467';
export const LIGHT_SHADE5 = '#D7A267';
export const LIGHT_SHADE6 = '#E1E1E1';

export const DARK_SHADE1 = '#60607F';
export const DARK_SHADE3 = '#6765A6';
export const DARK_SHADE4 = '#6D6ACC';
export const DARK_SHADE5 = '#7188DC';
export const DARK_SHADE6 = '#75A5EB';

// use chart colors from this pallete
export const CHART_COLOR_1 = 'rgb(153, 0, 204)';
export const CHART_COLOR_2 = 'rgb(255, 153, 51)';
export const CHART_COLOR_3 = 'rgb(75,0,130)';
export const CHART_COLOR_4 = 'rgb(168, 181, 4)';
export const CHART_COLOR_5 = 'rgb(122, 0, 204)';
export const CHART_COLOR_6 = 'rgb(204, 51, 255)';

// new chart colors
export const COLOR_1 = '#9365A6';
export const COLOR_2 = '#E768A8';
export const COLOR_3 = '#9657D5';
export const COLOR_4 = '#67B7DC';
export const COLOR_5 = '#707ED6';
export const COLOR_6 = '#99BAEB';

// gray colors shade
export const GRAY_1 = '#DCDCDC';
export const GRAY_2 = '#D3D3D3';
export const GRAY_3 = '#C0C0C0';
export const GRAY_4 = '#A9A9A9';
export const GRAY_5 = '#808080';
export const GRAY_6 = '#F5F5F5';

export const WHITE = '#fff';
export const DISABLED_COLOR_LIGHT = 'lightgrey';
export const DISABLED_COLOR_DARK = 'darkgrey';