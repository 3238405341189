import React, { useContext } from 'react';
import ColumnConstants from '@components/table/column/ColumnConstants';
import GenwizardTable from '@components/table/GenwizardTable';
import AddAppContext from '@context/addApp/AddAppContext';
// import { successStatusTick, successTick } from '@utils/ImageUtils';

// import UploadFile from './UploadFile';

function PreviewAndSubmit() {
    const {
        appLevelDetails,
        captureBpmLevels,
        repoTableData,
        captureAgileDetails,
        performChildAction
    } = useContext(AddAppContext);

    const shouldShowAgilePreview =
        captureAgileDetails.agileToolEndpoint ||
        captureAgileDetails.agileToolType ||
        captureAgileDetails.agileProjectName;
    return (
        <div className="app-container">
            <h6 className="c-repo__title">
                Review your application details and Submit
            </h6>
            <h6 className="c-repo__title">Application Details</h6>
            <div className="c-repo__row -repo-form">
                <div className="c-repo__col">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="name">
                            Name
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Placeholder"
                            value={appLevelDetails.name}
                            id="name"
                        />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                <div className="c-repo__col -full">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="desc">
                            Description
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Placeholder"
                            value={appLevelDetails.description}
                            id="desc"
                        />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
            </div>
            {/* <div className="c-repo__upload">
                <label className="-label">Attachments</label>
                <UploadFile />
            </div> */}
            <h6 className="c-repo__title">Business Details</h6>
            <div className="c-repo__row -bpm">

                <div className="c-repo__col">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="bsnsdmn">
                            Business Domain
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Placeholder"
                            value={captureBpmLevels.businessDomain}
                            id="bsnsdmn"
                        />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                <div className="c-repo__col">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="bsnskpi">
                            Business KPIs
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Placeholder"
                            value={captureBpmLevels.businessKPIs}
                            id="bsnskpi"
                        />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                <div className="c-repo__col">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="itkpi">
                            IT KPIs
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Placeholder"
                            value={captureBpmLevels.itKPIs}
                            id="itkpi"
                        />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                <div className="c-repo__col">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="lev1">
                            BPM Level 1
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Placeholder"
                            value={captureBpmLevels.bpmLevel1}
                            id="lev1"
                        />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                <div className="c-repo__col">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="lev2">
                            BPM Level 2
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Placeholder"
                            value={captureBpmLevels.bpmLevel2}
                            id="lev2"
                        />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                <div className="c-repo__col">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="lev3">
                            BPM Level 3
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Placeholder"
                            value={captureBpmLevels.bpmLevel3}
                            id="lev3"
                        />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div>
                {/* Commenting BPM level 4 for add and edit */}
                {/* <div className="c-repo__col">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="lev4">
                            BPM Level 4
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Placeholder"
                            value={captureBpmLevels.bpmLevel4}
                            id="lev4"
                        />
                        <small className="o-input__small-text"></small>
                        <p className="o-input__error-text">Enter the value</p>
                        <span className="o-input__char-text">
                            30/30 characters
                        </span>
                    </div>
                </div> */}
            </div>

            {/* agile */}

            {shouldShowAgilePreview && (
                <>
                    <h6 className="c-repo__title">Agile Details</h6>
                    <div className="c-repo__row -bpm">
                        {captureAgileDetails.agileToolEndpoint && (
                            <div className="c-repo__col">
                                <div className="o-input     ">
                                    <label className="-label" htmlFor="lev1">
                                        Agile Tool Endpoint
                                    </label>
                                    <input
                                        type="text"
                                        className="o-input__input"
                                        placeholder="Placeholder"
                                        value={
                                            captureAgileDetails.agileToolEndpoint
                                        }
                                        id="lev1"
                                    />
                                </div>
                            </div>
                        )}
                        {captureAgileDetails.agileToolType && (
                            <div className="c-repo__col">
                                <div className="o-input     ">
                                    <label className="-label" htmlFor="lev2">
                                        Agile Tool Type
                                    </label>
                                    <input
                                        type="text"
                                        className="o-input__input"
                                        placeholder="Placeholder"
                                        value={
                                            captureAgileDetails.agileToolType
                                        }
                                        id="lev2"
                                    />
                                </div>
                            </div>
                        )}
                        {captureAgileDetails.agileProjectName && (
                            <div className="c-repo__col">
                                <div className="o-input     ">
                                    <label className="-label" htmlFor="lev3">
                                        Agile Project Name
                                    </label>
                                    <input
                                        type="text"
                                        className="o-input__input"
                                        placeholder="Placeholder"
                                        value={
                                            captureAgileDetails.agileProjectName
                                        }
                                        id="lev3"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
            {/* {repoTableData.length > 0 ? (
                <>
                    <h6 className="c-repo__title">Repository Details</h6>
                    <GenwizardTable
                        colData={ColumnConstants(
                            'addAppRepoTable',
                            performChildAction
                        )}
                        data={repoTableData}
                    />
                </>
            ) : (
                <>
                    <h6 className="c-repo__title">Repository Details</h6>
                    <GenwizardTable
                        colData={ColumnConstants(
                            'addAppRepoTable',
                            performChildAction
                        )}
                        data={[]}
                    />
                </>
            )} */}
        </div>
    );
}

export default PreviewAndSubmit;
