import React, { useState } from 'react';

import FilterContext from './FilterContext';

const FilterContextProvider = ({ children }) => {
    const [filterTableData, setFilterTableData] = useState();
    const [defaultFilterTableData, setDefaultFilterTableData] = useState();
    const [finalSelectedData, setFinalSelectedData] = useState();
    const [applyButtonTrigger, setApplyButtonTrigger] = useState(false);
    const [fromCloudAdminRun, setFromCloudAdminRun] = useState(false);

    return (
        <FilterContext.Provider
            value={{
                filterTableData,
                setFilterTableData,
                finalSelectedData,
                setFinalSelectedData,
                defaultFilterTableData,
                setDefaultFilterTableData,
                applyButtonTrigger,
                setApplyButtonTrigger,
                fromCloudAdminRun,
                setFromCloudAdminRun
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};

export default FilterContextProvider;
