import React, { useEffect, useState } from 'react';
import FilterRow from '@components/chip/FilterRow';
import { GET_BPM_LEVELS, GET_CATAGORY_LIST } from '@graphql/queries';
import { getLazyQuery } from '@utils/Util';

import useClickOutside from '../../hooks/UseClickOutside';

const FilterBtn = ({ filterData, setFilterData }) => {
    const [show, setShow] = useState(false);

    const [finalObj, setFinalObj] = useState({});
    const [businessDomain, setBusinessDomain] = useState('');
    const [level1, setLevel1] = useState('');
    const [level2, setLevel2] = useState('');
    const [level3, setLevel3] = useState('');
    const [businessKpi, setBusinessKpi] = useState('');
    const [itKpi, setItKpi] = useState('');
    const [changedLevel, setChangedLevel] = useState('');

    const [fetchLabelList, { data: labelList }] = getLazyQuery(GET_BPM_LEVELS);

    const [fetchCatagoryList, { data: catagoryList }] = getLazyQuery(
        GET_CATAGORY_LIST,
        {
            orgName: sessionStorage.getItem("ORG_NAME")
        }
    );

    const filterClickOutside = useClickOutside(() => {
        setShow(false);
    });

    const handleApplyFilter = () => {
        const tempObj = {};
        if (businessDomain !== '') tempObj.businessDomain = businessDomain;
        if (level1 !== '') tempObj.l1BusinessProcess = level1;
        if (level2 !== '') tempObj.l2BusinessProcess = level2;
        if (level3 !== '') tempObj.l3BusinessProcess = level3;
        if (businessKpi !== '') tempObj.businessKPIs = businessKpi;
        if (itKpi !== '') tempObj.itKPIs = itKpi;
        setFilterData(tempObj);
        setShow(false);
    };

    useEffect(() => {
        if (filterData) {
            setBusinessDomain(filterData.businessDomain || '');
            setLevel1(filterData.l1BusinessProcess || '');
            setLevel2(filterData.l2BusinessProcess || '');
            setLevel3(filterData.l3BusinessProcess || '');
            setBusinessKpi(filterData.businessKPIs || '');
            setItKpi(filterData.itKPIs || '');
        }
    }, [filterData]);

    useEffect(() => {
        if (catagoryList) {
            setFinalObj({
                ...finalObj,
                'Business Domain': catagoryList?.getcategoryList || []
            });
        }
    }, [catagoryList]);

    useEffect(() => {
        if (labelList) {
            setFinalObj({
                ...finalObj,
                [changedLevel]: labelList?.getbpmLevels || []
            });
        }
    }, [labelList]);

    useEffect(() => {
        fetchCatagoryList();
    }, []);

    useEffect(() => {
        setChangedLevel('L1 Business process');
        if (level1 !== '') setLevel1('');
        if (level2 !== '') setLevel2('');
        if (level3 !== '') setLevel3('');
        if (businessKpi !== '') setBusinessKpi('');
        if (itKpi !== '') setItKpi('');

        fetchLabelList({
            variables: {
                paramHeader: 'Business Domain',
                paramKey: businessDomain,
                orgName: sessionStorage.getItem("ORG_NAME")
            }
        });
    }, [businessDomain]);

    useEffect(() => {
        setChangedLevel('L2 Business process');
        if (level2 !== '') setLevel2('');
        if (level3 !== '') setLevel3('');
        if (businessKpi !== '') setBusinessKpi('');
        if (itKpi !== '') setItKpi('');
        fetchLabelList({
            variables: {
                paramHeader: 'L1 Business process',
                paramKey: level1,
                orgName: sessionStorage.getItem("ORG_NAME")
            }
        });
    }, [level1]);

    useEffect(() => {
        setChangedLevel('L3 Business process');
        if (level3 !== '') setLevel3('');
        if (businessKpi !== '') setBusinessKpi('');
        if (itKpi !== '') setItKpi('');
        fetchLabelList({
            variables: {
                paramHeader: 'L2 Business process',
                paramKey: level2,
                orgName: sessionStorage.getItem("ORG_NAME")
            }
        });
    }, [level2]);

    useEffect(() => {
        setChangedLevel('Business KPI');
        if (businessKpi !== '') setBusinessKpi('');
        if (itKpi !== '') setItKpi('');
        fetchLabelList({
            variables: {
                paramHeader: 'L3 Business process',
                paramKey: level3,
                orgName: sessionStorage.getItem("ORG_NAME")
            }
        });
    }, [level3]);

    useEffect(() => {
        setChangedLevel('IT KPI');
        if (itKpi !== '') setItKpi('');
        fetchLabelList({
            variables: {
                paramHeader: 'Business KPI',
                paramKey: businessKpi,
                orgName: sessionStorage.getItem("ORG_NAME")
            }
        });
    }, [businessKpi]);

    return (
        <div
            ref={filterClickOutside}
            className={`c-dropdown c-filter-dropdown -right ${show && '-open'}`}
        >
            <a
                href="javascript:;"
                className="c-filter-toggle icon-arrow-down"
                aria-expanded={show}
                onClick={() => setShow(!show)}
                // aria-expanded="false"
            >
                Filter
            </a>
            {show && (
                <div
                    className="c-dropdown-menu"
                    style={{
                        position: show && 'absolute',
                        inset: show && '0px 0px auto auto',
                        margin: show && '0px',
                        transform:
                            show && 'translate3d(-72.6667px, -58px, 0px)',
                        display: show ? 'block' : 'none'
                    }}
                >
                    <div className="c-dropdown-body">
                        <div
                            className="c-filter-dropdown__sort"
                            // ADDED CUSTOM STYLE
                            style={{ paddingBottom: '0', marginBottom: '1rem' }}
                        >
                            <div className="c-filter-dropdown__header">
                                <div className="c-filter-dropdown__title">
                                    {/* SORT BY */}
                                </div>
                                <a
                                    href="javascript:;"
                                    className="c-filter-dropdown__clear"
                                    onClick={() => {
                                        setBusinessDomain('');
                                        setFilterData({});
                                    }}
                                >
                                    Clear all
                                </a>
                            </div>
                            {/* <div className="c-filter-dropdown__options">
                            <div className="o-checkbox">
                                <input
                                    type="checkbox"
                                    id="relevant"
                                    className="o-checkbox__input"
                                    name="unitcheckbox"
                                />
                                <i className="o-checkbox__checked"></i>
                                <label
                                    htmlFor="relevant"
                                    className="o-checkbox__label"
                                >
                                    Most relevant
                                </label>
                            </div>
                            <div className="o-checkbox">
                                <input
                                    type="checkbox"
                                    id="new"
                                    className="o-checkbox__input"
                                    name="unitcheckbox"
                                />
                                <i className="o-checkbox__checked"></i>
                                <label
                                    htmlFor="new"
                                    className="o-checkbox__label"
                                >
                                    Newly Added
                                </label>
                            </div>
                        </div> */}
                        </div>
                        <div className="c-filter-dropdown__filter">
                            <FilterRow
                                levelName="Business Domain"
                                chipsList={finalObj['Business Domain']}
                                value={businessDomain}
                                setValue={setBusinessDomain}
                            />
                            {businessDomain && (
                                <FilterRow
                                    levelName="L1 Business process"
                                    chipsList={
                                        finalObj['L1 Business process'] || []
                                    }
                                    value={level1}
                                    setValue={setLevel1}
                                />
                            )}
                            {level1 && (
                                <FilterRow
                                    levelName="L2 Business process"
                                    chipsList={
                                        finalObj['L2 Business process'] || []
                                    }
                                    value={level2}
                                    setValue={setLevel2}
                                />
                            )}
                            {level2 && (
                                <FilterRow
                                    levelName="L3 Business process"
                                    chipsList={
                                        finalObj['L3 Business process'] || []
                                    }
                                    value={level3}
                                    setValue={setLevel3}
                                />
                            )}
                            {level3 && (
                                <FilterRow
                                    levelName="Business KPI"
                                    chipsList={finalObj['Business KPI'] || []}
                                    value={businessKpi}
                                    setValue={setBusinessKpi}
                                />
                            )}
                            {businessKpi && (
                                <FilterRow
                                    levelName="IT KPI"
                                    chipsList={finalObj['IT KPI'] || []}
                                    value={itKpi}
                                    setValue={setItKpi}
                                />
                            )}
                            <a
                                href="javascript:void(0)"
                                role="button"
                                className="o-primary-button"
                                onClick={handleApplyFilter}
                            >
                                Apply
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilterBtn;
