import React, { useState } from 'react';

import AddAppContext from './AddAppContext';

const steps = [
    'Add App',
    'Business Details',
    'Agile Details',
    // 'Add repo',
    'Preview & Submit'
];
const editSteps = [
    'Edit App',
    'Edit BPM levels',
    'Edit Agile Details',
    // 'Edit repo',
    'Preview & Submit'
];
function AddAppContextProvider({ children }) {
    const [currentPage, setCurrentPage] = useState(0);
    const [addAppRepoDetails, setAddAppRepoDetails] = useState({
        repoName: '',
        repoPath: '',
        branchName: '',
        username: '',
        pwd: ''
    });
    const [repoTableData, setRepTableData] = useState([]);
    const [captureBpmLevels, setCaptureBpmLevels] = useState({
        businessDomain: '',
        businessKPIs: '',
        itKPIs: '',
        bpmLevel1: '',
        bpmLevel2: '',
        bpmLevel3: '',
        bpmLevel4: ''
    });
    const [captureAgileDetails, setCaptureAgileDetails] = useState({
        agileToolEndpoint: '',
        agileToolType: '',
        agileProjectName: ''
    });
    const [appLevelDetails, setApplevelDetails] = useState({
        name: '',
        description: ''
    });
    const [isNextPageEnabled, setIsNextPageEnabled] = useState(false);

    const [data, setData] = useState([]);

    const [fileDropVars, setFileDropVars] = useState({
        isFileDropped: false,
        toMap: [],
        fileName: '',
        fileData: [],
        fileSize: null
    });

    // for add app api call
    const [captureAddAppErr, setCaptureAddAppErr] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [successAddApp, setSuccessAddApp] = useState();

    // removes and filters item from actuabl table array via child action

    function handleRemoveRepo(incomingRow) {
        setRepTableData(current => {
            return current.filter(item => item !== incomingRow);
        });
    }

    const performChildAction = (actionType, rowData) => {
        switch (actionType) {
            case 'removeRepo': {
                return handleRemoveRepo(rowData);
            }
            default:
                return null;
        }
    };
    return (
        <AddAppContext.Provider
            value={{
                currentPage,
                setCurrentPage,
                captureAgileDetails,
                setCaptureAgileDetails,
                steps,
                editSteps,
                addAppRepoDetails,
                setAddAppRepoDetails,
                repoTableData,
                setRepTableData,
                performChildAction,
                captureBpmLevels,
                setCaptureBpmLevels,
                isNextPageEnabled,
                setIsNextPageEnabled,
                appLevelDetails,
                setApplevelDetails,
                data,
                setData,
                fileDropVars,
                setFileDropVars,
                captureAddAppErr,
                setCaptureAddAppErr,
                showDialog,
                setShowDialog,
                successAddApp,
                setSuccessAddApp
            }}
        >
            {children}
        </AddAppContext.Provider>
    );
}

export default AddAppContextProvider;
