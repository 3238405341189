import React, { useContext } from 'react';
import { key } from '@constants/Constants';
import AddAppContext from '@context/addApp/AddAppContext';
import AppContext from '@context/app/AppContext';
import { FORM_BASED_ADD_APP } from '@graphql/mutations';
import {
    decodeErrorMessage,
    encryptData,
    getMutation,
    getToken
} from '@utils/Util';
import PropTypes from 'prop-types';

function ButtonCard({
    setCurrentPage,
    maxPage,
    currentPage,
    handleFallBackRoute
}) {
    const {
        isNextPageEnabled,
        appLevelDetails,
        captureBpmLevels,
        repoTableData,
        fileDropVars,
        setCaptureAddAppErr,
        showDialog,
        setShowDialog,
        successAddApp,
        setSuccessAddApp,
        captureAddAppErr,
        captureAgileDetails
    } = useContext(AddAppContext);
    const { setIsSpinnerNeeded } = useContext(AppContext);

    console.log('REPOTABLEDATA', repoTableData);
    const maxPageLimit = currentPage + 1 === maxPage;
    const handleNextPage = () => {
        if (maxPageLimit) return;
        setCurrentPage(prev => prev + 1);
    };

    const handlePrevPage = () => {
        if (currentPage === 0) handleFallBackRoute();
        setCurrentPage(prev => prev - 1);
    };
    const handleRepoDteails = incomingArr => {
        return incomingArr.map(each => {
            const { username, pwd, repoPath, ...rest } = each;
            return {
                ...rest,
                repoUrl: repoPath,
                repoUsername: encryptData(username, key),
                repoPassword: encryptData(pwd, key)
            };
        });
    };
    console.log(fileDropVars, 'fileDropVars');

    const [formBasedAddApps] = getMutation(FORM_BASED_ADD_APP);
    const handleSubmitAddRepo = async () => {
        setIsSpinnerNeeded(true);
        const payloadObject = {
            appName: appLevelDetails.name,
            orgName: sessionStorage.getItem("ORG_NAME"),
            description: appLevelDetails.description,
            businessDomain: captureBpmLevels.businessDomain,
            businessKPIs: captureBpmLevels.businessKPIs,
            itKPIs: captureBpmLevels.itKPIs,
            // businessDomain: 'Life Science',
            // businessKPIs: 'tes1',
            // itKPIs: 'test1',
            attachments: fileDropVars.fileData,
            l1BusinessProcess: captureBpmLevels.bpmLevel1,
            l2BusinessProcess: captureBpmLevels.bpmLevel2,
            l3BusinessProcess: captureBpmLevels.bpmLevel3,
            reposDetails: handleRepoDteails(repoTableData),
            AgileToolType: captureAgileDetails.agileToolEndpoint,
            AgileToolEndpoint: captureAgileDetails.agileToolType,
            ExistingAgileProjectName: captureAgileDetails.agileProjectName
        };
        console.log({ payloadObject, captureAgileDetails }, 'arz');

        try {
            await formBasedAddApps({
                variables: payloadObject,
                context: {
                    headers: getToken()
                },
                fetchPolicy: 'no-cache'
            });
            setSuccessAddApp(true);
            setCaptureAddAppErr('');
        } catch (err) {
            setCaptureAddAppErr(decodeErrorMessage(err));
            setSuccessAddApp(false);
        } finally {
            setIsSpinnerNeeded(false);
            setShowDialog(true);
        }
    };

    return (
        <div className="c-select-card">
            <div className="app-container">
                <div className="c-select-card__wrapper">
                    <div className="c-select-card__left"></div>
                    <div className="c-select-card__right">
                        <a
                            href="javascript:void(0)"
                            role="button"
                            className="o-secondary-button"
                            onClick={() => handlePrevPage()}
                        >
                            Back
                        </a>

                        <a
                            href="javascript:void(0)"
                            role="button"
                            className={`o-primary-button ${
                                !isNextPageEnabled ? '-disabled' : ''
                            }`}
                            onClick={() => {
                                if (maxPageLimit) {
                                    handleSubmitAddRepo();
                                } else {
                                    handleNextPage();
                                }
                            }}
                        >
                            {maxPageLimit ? 'Submit' : 'Proceed'}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
ButtonCard.propTypes = {
    setCurrentPage: PropTypes.func,
    handleFallBackRoute: PropTypes.func,
    maxPage: PropTypes.number,
    currentPage: PropTypes.number
};

ButtonCard.defaultProps = {
    setCurrentPage: () => {},
    handleFallBackRoute: () => {},
    maxPage: 0,
    currentPage: 0
};

export default ButtonCard;
