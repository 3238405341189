import React, { useState } from 'react';

import ChatContext from './ChatContext';

const ChatContextProvider = ({ children }) => {
    const [inputForPrompt, setInputForPrompt] = useState('');
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [chatRcaTab, setChatRcaTab] = useState([]);

    return (
        <ChatContext.Provider
            value={{
                inputForPrompt,
                setInputForPrompt,
                isButtonClicked,
                setIsButtonClicked,
                chatRcaTab,
                setChatRcaTab
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};

export default ChatContextProvider;
