// eslint-disable-next-line import/no-duplicates
import React from 'react';
// eslint-disable-next-line import/no-duplicates
import { useEffect, useState } from 'react';
import { DISABLED_COLOR_LIGHT } from '@constants/ColorConstants';
import "./CurrentRuns.css"

const TableHeader = ({
    columns,
    curateTableFlag,
    appSummaryTableFlag,
    techDebtIssueFlag
}) => {
    const appSummaryCustomStyle = {
        textDecoration: 'none',
        cursor: 'auto'
    };
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 120) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <thead
            className={
                techDebtIssueFlag
                    ? 'run-header'
                    : ` run-header ${isSticky ? 'sticky' : ''}`
            }
        >
            <tr>
                {columns.map((headerItem, index) => (
                    <th
                        key={`UR-${index}`}
                        scope={headerItem.scope || 'col'}
                        width={headerItem.width || ''}
                        className={headerItem.className || ''}
                        colSpan={headerItem.colSpan || ''}
                    >
                        {headerItem?.colType === 'checkbox' ? (
                            <div className="o-checkbox">
                                <input
                                    type="checkbox"
                                    id="check-one"
                                    className="o-checkbox__input"
                                    name="unitcheckbox"
                                    onChange={() => {
                                        headerItem.colFunction('selectAllRows');
                                    }}
                                    checked={headerItem.colFunction(
                                        'masterCheckBoxValue'
                                    )}
                                    disabled={
                                        headerItem.colFunction(
                                            'disableAllRowsSelection'
                                        ) && true
                                    }
                                />
                                <i
                                    className="o-checkbox__checked"
                                    style={{
                                        background:
                                            headerItem.colFunction(
                                                'disableAllRowsSelection'
                                            ) && DISABLED_COLOR_LIGHT
                                    }}
                                ></i>
                            </div>
                        ) : (
                            <a
                                href="javascript:;"
                                className={
                                    appSummaryTableFlag ? '' : 'c-table-tooltip'
                                }
                                // data-myW-title={
                                //     headerItem.tooltipTitle
                                //         ? headerItem.tooltipTitle
                                //         : ''
                                // }
                                // data-myW-toggle="tooltip"
                                // data-myW-placement="bottom"
                                // data-myW-custClass="-testTooltip"
                                style={
                                    appSummaryTableFlag
                                        ? appSummaryCustomStyle
                                        : {}
                                }
                            >
                                {headerItem.title ? headerItem.title : ''}
                            </a>
                        )}
                    </th>
                ))}
            </tr>
            {(curateTableFlag || appSummaryTableFlag) && (
                <tr>
                    {columns.map((headerItem, index) => {
                        return (
                            <>
                                {headerItem?.childCols ? (
                                    <>
                                        {headerItem?.childCols?.length > 0 &&
                                            headerItem.childCols.map(
                                                (
                                                    childColItem,
                                                    childColIndex
                                                ) => (
                                                    <th
                                                        key={`LR-${index}-${childColIndex}`}
                                                        width={
                                                            headerItem?.childWidth ||
                                                            ''
                                                        }
                                                        className={
                                                            headerItem.childClassName ||
                                                            ''
                                                        }
                                                    >
                                                        {childColItem.label}
                                                    </th>
                                                )
                                            )}
                                    </>
                                ) : (
                                    <th key={`LR-${index}`} scope="col"></th>
                                )}
                            </>
                        );
                    })}
                </tr>
            )}
        </thead>
    );
};

export default TableHeader;
