import { ApolloClient, InMemoryCache } from '@apollo/client';
import useKeycloak from '@containers/keycloak/keycloak';
import { getUploadLink } from '@utils/Util';

function useClient() {
    const cache = new InMemoryCache();
    const { keycloak } = useKeycloak(process.env.REACT_APP_DEFAULT_REALM);
    const client = new ApolloClient({
        cache,
        link: getUploadLink(keycloak)
    });

    return client;
}

export default useClient;
