import React, { useState } from 'react';

import AddRepoContext from './AddRepoContext';

const AddRepoContextProvider = ({ children }) => {
    const [formData, setFormData] = useState({});
    const [errorMsg, setErrorMsg] = useState({
        repoName: [],
        repoPath: [],
        branchName: [],
        username: [],
        pass: []
    });
    const repoHasErrors =
        errorMsg.repoName.length > 0 ||
        errorMsg.repoPath.length > 0 ||
        errorMsg.branchName.length > 0 ||
        errorMsg.username.length > 0 ||
        errorMsg.pass.length > 0;

    return (
        <AddRepoContext.Provider
            value={{
                formData,
                setFormData,
                errorMsg,
                setErrorMsg,
                repoHasErrors
            }}
        >
            {children}
        </AddRepoContext.Provider>
    );
};

export default AddRepoContextProvider;
