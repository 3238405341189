import React, { useContext, useEffect, useState } from 'react';
import CaptureFormError from '@components/ui/Forms/components/CaptureFormError';
import AddAppContext from '@context/addApp/AddAppContext';

function AgileForm() {
    const [agileErr, setAgileError] = useState({
        agileToolEndpoint: [],
        agileToolType: [],
        agileProjectName: []
    });
    const {
        captureAgileDetails,
        setCaptureAgileDetails,
        isNextPageEnabled,
        setIsNextPageEnabled
    } = useContext(AddAppContext);

    useEffect(() => {
        setIsNextPageEnabled(true);
    }, []);

    return (
        <div className="app-container">
            <h6 className="c-repo__title">
                Add Agile details for the application
            </h6>
            <div className="c-repo__row">
                <div className="c-repo__col add_app__input">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="lev1">
                            Agile Tool Endpoint
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Enter Tool Endpoint"
                            id="lev1"
                            value={captureAgileDetails.agileToolEndpoint}
                            onChange={e => {
                                e.persist();

                                setCaptureAgileDetails(prev => ({
                                    ...prev,
                                    agileToolEndpoint: e.target.value
                                }));
                            }}
                        />
                        <CaptureFormError errors={agileErr.bpmLevel1} />
                    </div>
                </div>
                <div className="c-repo__col add_app__input">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="lev2">
                            Agile Tool Type
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Enter Tool Type"
                            value={captureAgileDetails.agileToolType}
                            id="lev2"
                            onChange={e => {
                                e.persist();

                                setCaptureAgileDetails(prev => ({
                                    ...prev,
                                    agileToolType: e.target.value
                                }));
                            }}
                        />
                        <CaptureFormError errors={agileErr.agileToolType} />
                    </div>
                </div>
                <div className="c-repo__col add_app__input">
                    <div className="o-input     ">
                        <label className="-label" htmlFor="lev3">
                            Agile Project Name
                        </label>
                        <input
                            type="text"
                            className="o-input__input"
                            placeholder="Enter Project Name"
                            value={captureAgileDetails.agileProjectName}
                            id="lev3"
                            onChange={e => {
                                e.persist();

                                setCaptureAgileDetails(prev => ({
                                    ...prev,
                                    agileProjectName: e.target.value
                                }));
                            }}
                        />
                        <CaptureFormError errors={agileErr.bpmLevel3} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AgileForm;
