import { gql } from '@apollo/client';

// FileDownload.js
export const UPLOAD_FILE_FOR_ONBOARD = gql`
    mutation onboardApp($file: Upload, $type: String!) {
        onboardApp(input: { file: $file, type: $type })
    }
`;

// OnboardApp.js
export const SUBMIT_FOR_ONBOARD_APP = gql`
    mutation addApp($appsDetails: [JSON], $orgName: String) {
        addApp(input: { appsDetails: $appsDetails, orgName: $orgName })
    }
`;


export const FORM_BASED_ADD_APP = gql`
    mutation formBasedAddApp(
        $appName: String!
        $orgName: String!
        $description: String!
        $businessDomain: String!
        $businessKPIs: String!
        $itKPIs: String!
        $l1BusinessProcess: String!
        $l2BusinessProcess: String!
        $l3BusinessProcess: String!
        $attachments: [Upload]!
        $reposDetails: [reposDetailsInput]!
        $AgileToolEndpoint: String
        $AgileToolType: String
        $ExistingAgileProjectName: String
    ) {
        formBasedAddApp(
            input: {
                appName: $appName
                orgName: $orgName
                description: $description
                businessDomain: $businessDomain
                businessKPIs: $businessKPIs
                attachments: $attachments
                itKPIs: $itKPIs
                l1BusinessProcess: $l1BusinessProcess
                l2BusinessProcess: $l2BusinessProcess
                l3BusinessProcess: $l3BusinessProcess
                reposDetails: $reposDetails
                AgileToolType: $AgileToolType
                AgileToolEndpoint: $AgileToolEndpoint
                ExistingAgileProjectName: $ExistingAgileProjectName
            }
        )
    }
`;
