/* eslint-disable */ 
import React, { Suspense, useState, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import { ApolloProvider } from '@apollo/client';
import RoutingModule from '@components/RoutingModule';
import AppContextProvider from '@context/app/AppContextProvider';
import ChatContextProvider from '@context/chatbot/ChatContextProvider';
import useClient from '../../hooks/UseClient';
import '../../assets/toolkit/styles/toolkit.css';
import '../../assets/styles/Style.css';
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import useKeycloak from '../keycloak/keycloak';
import {Router} from 'react-router-dom';
import History from '@utils/History';

function App() {
    const [show, setShow] = useState(false);
    const [authenticated, setAuthentication] = useState(false);
    const { keycloak } = useKeycloak(process.env.REACT_APP_DEFAULT_REALM);
    
    const handleIdle = () => {
        window.localStorage.setItem('Extended', 'false');
        setShow(true);

        window.addEventListener('storage', event => {
            if (event.storageArea !== localStorage) return;
            if (event.key === 'Extended') {
                setShow(false);
            }
        });
    };

    const refreshSession = () => {
        keycloak.updateToken(180).then(() => {});
    };

    if (keycloak && keycloak.tokenParsed) {
        setInterval(() => refreshSession(), 180 * 1000);
    }

    useEffect(() => {
        keycloak.init({ onLoad: 'login-required' })
        .then(isAuthenticated => {
            setAuthentication(isAuthenticated);
        })
        .catch(err => {
            const newErr = err;
        });
    }, []);

    return (authenticated ? (
        authenticated.error ? (
            <p className="error">
                Error Occurred:{JSON.stringify(authenticated.error)}
            </p>
        ) : (<>
            <IdleTimer
                onIdle={handleIdle}
                timeout={1000 * 60 * 15}
                crossTab={{ emitOnAllTabs: true }}
            >
                <AppContextProvider>
                    <ChatContextProvider>
                        <ApolloProvider client={useClient()}>
                            <Suspense
                                fallback={
                                    <div className="centered-load">
                                        {/* <img
                                                        src={loading}
                                                        alt="loading"
                                                    /> */}
                                    </div>
                                }
                            >
                                <Router basename="/" history={History}>
                                <RoutingModule />
                                </Router>
                            </Suspense>
                        </ApolloProvider>
                    </ChatContextProvider>
                </AppContextProvider>
                {/* {show && <div>Session inactive for 15 mins!</div>} */}
                {show}
            </IdleTimer>
        </>
        )
        ): (
           ''
        ));
}

export default App;
