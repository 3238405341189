import React from 'react';

const Footer = () => {
    return (
        <footer style={{
            display:"flex",
            borderTop:"1px solid #ccc",
            width:"100%",
            padding:"0.75rem 4.75rem",
            justifyContent:"space-between",
            fontSize:"0.75rem"
        }}>
            <div className="c-footer__legalDeclaration">
                {`Copyright 2001 - ${new Date().getFullYear()} Accenture. All rights reserved.
                Accenture Confidential. For internal use only.`}
            </div>
            <div className="c-footer__legalStatements">
                <a href="javascript:;">Terms of Use</a>
                <a href="javascript:;">Privacy Statement</a>
            </div>
        </footer>
    );
};

export default Footer;