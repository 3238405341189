import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BpmLevelChip from '@components/chip/BpmLevelChip';
import ColumnConstants from '@components/table/column/ColumnConstants';
import GenwizardTable from '@components/table/GenwizardTable';
import { Routes } from '@constants/RoutingConstants';
import AddRepoContextProvider from '@context/addRepo/AddRepoContextProvider';
import AppContext from '@context/app/AppContext';
import { GET_REPO_LIST } from '@graphql/queries';
import { downloadBtn } from '@utils/ImageUtils';
import { getLazyQuery } from '@utils/Util';

const ViewOnboardAppContext = ({ location }) => {
    const rowData = location.state;
    const {
        agile_details: {
            AgileProjectName: agileProjectName = '',
            AgileToolEndpoint: agileToolEndpoint = '',
            AgileToolType: agileToolType = ''
        } = {}
    } = rowData || {};
    const { onboardapp } = useParams();

    const [repotableData, setRepoTableData] = useState([]);

    const { setOnboardAppName, setOnboardAppId , setRowData} = useContext(AppContext);

    const [
        fetchRepoList,
        { data: repoList, loading: isRepoListLoading, error: repoListError }
    ] = getLazyQuery(GET_REPO_LIST, {
        // appName: 'genwizardUI',
        appName: onboardapp,
        orgName: sessionStorage.getItem("ORG_NAME")
    });

    const isAgilePanelReqduired =
        agileProjectName || agileToolEndpoint || agileToolType;

    useEffect(() => {
        if (repoList) {
            setRepoTableData(repoList.getRepoList);
        }
    }, [repoList, isRepoListLoading]);

    useEffect(() => {
        fetchRepoList();
        setOnboardAppId(onboardapp);
        setOnboardAppName(onboardapp);
    }, []);

    useEffect(() => {
        setRowData(rowData);
    }, [rowData]);

    const performChildAction = (actionType) => {
        switch (actionType) {
            case 'recallGetRepoList':
                return fetchRepoList();

            default:
                return '';
        }
    };

    return (
        <>
            <div className="c-inventory-header">
                <div className="app-container">
                    <div className="c-inventory-header__container">
                        <div className="c-inventory-header__title">
                            {' '}
                            <Link
                                to={Routes.OnboardApps}
                                className="icon-banner-arrow">
                            </Link>
                            {onboardapp}
                            <a href="javascript:;" className="c-inventory_download_container">
                                <img src={downloadBtn} alt="download" />
                            </a>
                            {/* As of now disabling edit option: Need to remove pointerEvent to enable Edit option */}
                            <Link
                                to={{
                                    pathname: `${Routes.OnboardApps}/viewonboardapp/${rowData?.name}/editApp`
                                }}
                                className="c-inventory-header__edit" title='Edit App' style={{margin : '4px 0px 0px 6px' , pointerEvents: 'none'}}>
                                <i className="icon-edit"></i>
                            </Link>
                        </div>
                    </div>
                    {/* <div className="c-chip__list">
                            <span className="c-chip">Retail</span>
                            <span className="c-chip">Operating proces</span>
                            <span className="c-chip">
                                Sourcing and procurement
                            </span>
                            <span className="c-chip">order management</span>
                        </div> */}
                    <BpmLevelChip levels={rowData?.bpm || {}} notInTable />
                </div>
            </div>

            <div className="c-index">
                <div className="app-container">
                    <div className="c-inventory__item">
                        <h6 className="c-inventory__title">Description</h6>
                        <p className="c-inventory__desc">
                            {rowData?.description}
                        </p>
                    </div>
                    {isAgilePanelReqduired && (
                        <div className="c-inventory__item">
                            <div style={{ display: 'flex', gap: '4rem' }}>
                                {agileToolEndpoint && (
                                    <div>
                                        <h6 className="c-inventory__title">
                                            Agile Tool Endpoint
                                        </h6>
                                        <p className="c-inventory__desc">
                                            {agileToolEndpoint}
                                        </p>
                                    </div>
                                )}
                                {agileToolType && (
                                    <div>
                                        <h6 className="c-inventory__title">
                                            Agile Tool Type
                                        </h6>
                                        <p className="c-inventory__desc">
                                            {' '}
                                            {agileToolType}
                                        </p>
                                    </div>
                                )}
                                {agileProjectName && (
                                    <div>
                                        <h6 className="c-inventory__title">
                                            Agile Project Name
                                        </h6>
                                        <p className="c-inventory__desc">
                                            {agileProjectName}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {/* <div className="c-inventory__item">
                        <h6 className="c-inventory__title">App Manager</h6>
                        <p className="c-inventory__desc">
                            krishnakumar.eledath@accenture.com
                        </p>
                    </div> */}
                    <h6 className="c-inventory__title">Repository List</h6>
                    <GenwizardTable
                        colData={ColumnConstants(
                            'addRepoTable',
                            performChildAction
                        )}
                        data={repotableData}
                        isLoading={isRepoListLoading}
                        // isLoading={false}
                        // isError={false}
                        isError={repoListError && true}
                        isPagination={false}
                    />
                </div>
            </div>

            {/* <AddRepo /> */}
        </>
    );
};

const ViewOnboardApp = ({ ...props }) => {
    return (
        <AddRepoContextProvider>
            <ViewOnboardAppContext {...props} />
        </AddRepoContextProvider>
    );
};

export default ViewOnboardApp;
