import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FilterBtn from '@components/filter/FilterBtn';
import FilterChip from '@components/filterWithSearch/FilterChip';
import ColumnConstants from '@components/table/column/ColumnConstants';
import GenwizardTable from '@components/table/GenwizardTable';
import { Routes } from '@constants/RoutingConstants';
import { ONBOARD_APP_LIST } from '@graphql/queries';
import { add, backArrow, importIcon } from '@utils/ImageUtils';
import { getLazyQuery } from '@utils/Util';

const Onboard = () => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState();

    // const dummyData = [
    //     {
    //         appid: 'hdiwajdoiwa2313213',
    //         name: 'App 1',
    //         description: 'app 1 description',
    //         repo_count: 1,
    //         bpm: {
    //             businessDomain: 'bd',
    //             l1BusinessProcess: 'l1',
    //             l2BusinessProcess: 'l2',
    //             l3BusinessProcess: 'l3',
    //             businessKPIs: 'kpi1',
    //             itKPIs: 'itkpi'
    //         }
    //     }
    // ];

    const [
        fetchAppsList,
        { data: appsList, loading: isAppsListLoading, error: appsListError }
    ] = getLazyQuery(ONBOARD_APP_LIST, {
        orgName: sessionStorage.getItem("ORG_NAME")
    });

    const handleButton = type => {
        if (type === 'app') {
            history.push(Routes.NewApp);
        } else {
            history.push(Routes.OnboardNewApp);
        }
    };

    useEffect(() => {
        fetchAppsList();
    }, []);
    const filterList = (key, value, list) => {
        console.log({ key, value, list }, 'list from onboard');

        const newList = [];
        list.forEach(element => {
            if (element.bpm && element.bpm[key] && element.bpm[key] === value) {
                newList.push(element);
            }
        });

        return newList;
    };
    useEffect(() => {
        if (filterData) {
            let dataAfterFilter = appsList.getAppList;

            Object.keys(filterData).forEach(key => {
                console.log(
                    { key, test: filterData[key], dataAfterFilter },
                    'use onboard'
                );
                dataAfterFilter = filterList(
                    key,
                    filterData[key],
                    dataAfterFilter
                );
            });

            setData(dataAfterFilter);
        }
    }, [filterData]);

    useEffect(() => {
        if (appsList) {
            const tempdata = appsList.getAppList.map(item => {
                return { ...item };
            });
            setData(tempdata);
        }
    }, [appsList]);

    return (
        <>
            <div className="app-wrap">
                <div className="c-index c-curate">
                    {/* from here */}
                    <div className="c-curate__wrapper">
                        <div className="app-container">
                            <div className="c-curate__header">
                                <div className="c-curate__title">
                                    <a
                                        className="onboard_anchor__back"
                                        href="javascript:void(0)"
                                        role="button"
                                        onKeyDown={() => {}}
                                        onClick={() => {
                                            history.push(Routes.Manage);
                                        }}
                                    >
                                        <img
                                            src={backArrow}
                                            alt="back"
                                            role="presentation"
                                        />
                                    </a>
                                    Result:&nbsp;
                                    <span>{data.length} Applications</span>
                                </div>
                                <div className="c-curate__content">
                                    <FilterBtn
                                        filterData={filterData}
                                        setFilterData={setFilterData}
                                    />
                                    {filterData && Object.keys(filterData) && (
                                        <FilterChip
                                            filterData={filterData}
                                            setFilterData={setFilterData}
                                        />
                                    )}
                                    {/* <div className="c-search">
                                        <div className="c-search-dropdown">
                                            <div className="c-dropdown">
                                                <div
                                                    className="c-search-dropdown-area"
                                                    aria-expanded="false"
                                                    aria-haspopup="true"
                                                >
                                                    <a
                                                        href="javascript:;"
                                                        title="Search"
                                                        aria-label="Search"
                                                    >
                                                        <i className="icon-searchicon"></i>
                                                    </a>
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                    />
                                                    <a
                                                        href="javascript:;"
                                                        className="-search-close"
                                                        title="Close"
                                                        aria-label="Close"
                                                    >
                                                        <i className="icon-cross"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <a
                                            href="javascript:;"
                                            className="-searchicon"
                                            title="Search"
                                            aria-label="Search"
                                        >
                                            <i className="icon-searchicon"></i>
                                        </a>
                                    </div> */}
                                    <a
                                        href="javascript:;"
                                        className="c-onboard__link"
                                        onClick={() => handleButton('app')}
                                    >
                                        <img
                                            src={add}
                                            alt="Add"
                                            role="presentation"
                                            style={{ width: '0.9rem' }}
                                        />
                                        Add App
                                    </a>
                                    <a
                                        href="javascript:;"
                                        className="c-onboard__link"
                                        onClick={() =>
                                            handleButton('onboard app')
                                        }
                                    >
                                        <img
                                            src={importIcon}
                                            alt="Import"
                                            role="presentation"
                                            // style={{ width: '0.8rem' }}
                                        />
                                        Import App
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* to here */}

                    <div className="app-container">
                        <GenwizardTable
                            colData={ColumnConstants('onboardPageTable')}
                            data={data}
                            isLoading={isAppsListLoading}
                            isError={appsListError && true}
                            isPagination={false}
                            curateTableFlag
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Onboard;
