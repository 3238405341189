// eslint-disable-next-line import/prefer-default-export
export const Routes = {
    SplashScreen:"/",
    Dashboard: '/dashboard',
    Manage: '/manage',
    CurateApp: '/manage/explain',
    WikiApp: '/manage/wiki',
    Config: '/manage/config',
    CodeQuality: '/manage/codeQuality',
    TecDebtApp: '/manage/codeQuality/viewapp/:app',
    TecDebtIssue: '/manage/codeQuality/viewapp/:app/issue/:repo',
    WikiInventory: '/manage/wikiInventory/',
    ViewApp: '/manage/explain/viewapp/:app',
    ConfigApp: '/manage/explain/viewapp/:app/configure',
    Explore: '/manage/explore',
    Onboard: '/onboard',
    OnboardApps: '/onboard/allapps',
    ViewOnboardApp: '/onboard/allapps/viewonboardapp/:onboardapp',
    EditApps: '/onboard/allapps/viewonboardapp/:onboardapp/editApp',
    NewApp: '/onboard/allapps/addapp',
    OnboardNewApp: '/onboard/allapps/addonboardapp',
    ReleaseInfo: '/releaseinfo/info',
    CodeTranslation: '/manage/codetranslation',
    ViewCodeTranslationApp: '/manage/codetranslation/viewapp/:app'
};
