import React from 'react';
import PropTypes from 'prop-types';

function CaptureFormError({ errors, conditionalBreak }) {
    return (
        <div style={{ color: 'red' }}>
            {Array.isArray(errors) ? (
                errors.map((item, index) => {
                    return index !== errors.length - 1 ? (
                        <small key={index}>
                            {item}
                            {conditionalBreak ? <br /> : ''}{' '}
                        </small>
                    ) : (
                        <small key={index}>{item}</small>
                    );
                })
            ) : (
                <small>{errors}</small>
            )}
        </div>
    );
}

CaptureFormError.propTypes = {
    errors: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string
    ]),
    conditionalBreak: PropTypes.bool
};

CaptureFormError.defaultProps = {
    errors: null,
    conditionalBreak: null
};

export default CaptureFormError;
