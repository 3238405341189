import React, { useEffect, useState } from 'react';
import MicroFrontend from '@utils/MicroFrontend';

let ROOT_PATH = process.env.PUBLIC_URL;
let realm = process.env.REACT_APP_DEFAULT_REALM;

// import { process.env.REACT_APP_DEFAULT_REALM } from "@constants/Constants";

export default function MicroView({
    history,
    name,
    host,
    menuName,
    useKeycloak,
    globalConfigData,
    selectedApp
}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    useEffect(() => {
        console.log('in Micro view comp---- ', history);
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        if (isLoaded) {
            setIsPageLoaded(true);
        }
    }, [isLoaded]);

    if (!isPageLoaded) return 'Loading...';

    console.log(ROOT_PATH , realm , 'checkkkkkkkkkkkkkkkkkkkkkkkk second')

    return (
        <MicroFrontend
            useKeycloak={useKeycloak}
            history={history}
            host={host}
            name={name}
            rootUrl={`${ROOT_PATH}/${realm}`}
            menuName={menuName}
            globalConfigData={globalConfigData}
            selectedApp={selectedApp}
        />
    );
}
