import React from 'react';

const ExpandableRow = ({ extraColumns, key, item }) => {
    return (
        <tr key={key}>
            {extraColumns.map((col, index) => (
                <td colSpan="100%" key={index}>
                    {col.render(item, index)}
                </td>
            ))}
        </tr>
    );
};

export default ExpandableRow;
