import React, { useContext, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useMutation } from '@apollo/client';
import ErrorText from '@components/text/ErrorText';
import AppContext from '@context/app/AppContext';
import { UPLOAD_FILE_FOR_ONBOARD } from '@graphql/mutations';
import { uploadFileBtn } from '@utils/ImageUtils';
import { decodeErrorMessage, getToken } from '@utils/Util';
import * as XLSX from 'xlsx';

const FileUpload = ({ ...rest }) => {
    const [uploadFile] = useMutation(UPLOAD_FILE_FOR_ONBOARD);

    const { setIsSpinnerNeeded } = useContext(AppContext);
    const [errorMsg, setErrorMsg] = useState('');

    const onFileUpload = async (acceptedFiles, restProps) => {
        setIsSpinnerNeeded(true);
        setErrorMsg('');
        try {
            const { data } = await uploadFile({
                variables: {
                    file: acceptedFiles[0],
                    type: 'xlsx'
                },
                context: {
                    headers: getToken()
                },
                fetchPolicy: 'no-cache'
            });

            let hasUndefinedValue = false;
            if (data?.onboardApp?.length <= 0) {
                setErrorMsg('Empty file detected');
                return;
            }

            // eslint-disable-next-line no-unused-expressions
            // data?.onboardApp?.forEach(eachItem =>
            //     // eslint-disable-next-line array-callback-return
            //     Object.values(eachItem).every(item => {
            //         if (!item) {
            //             setErrorMsg('Blank rows are not allowed');
            //             hasUndefinedValue = true;
            //         }
            //     })
            // );

            // exits fn if complete row is empty from the uploaded excel
            // if (hasUndefinedValue) {
            //     return;
            // }

            restProps.setData(data.onboardApp);
            rest.setIsFileUploaded(true);
            rest.setIsFileDropped(true);
        } catch (err) {
            setErrorMsg(decodeErrorMessage(err));
        } finally {
            setIsSpinnerNeeded(false);
        }
    };

    const handleExcelDrop = (acceptedFiles, restProps) => {
        acceptedFiles.forEach(file => {
            if (file.name.includes('.xlsx') || file.name.includes('.xls')) {
                const size = `${(file.size / 1024).toFixed(2)} KB`;
                if ((file.size / 1024).toFixed(2) > 200) {
                    setErrorMsg('File size must not exceed 200 KB.');
                } else {
                    rest.setFileName(file.name);
                    rest.setFileSize(size);

                    // Check the number of sheets before uploading
                    checkExcelSheets(file, isValid => {
                        if (isValid) {
                            onFileUpload(acceptedFiles, restProps);
                        } else {
                            setErrorMsg(
                                'Only Excel files with a single sheet are allowed.'
                            );
                        }
                    });
                }
            } else {
                setErrorMsg(
                    'Only .xlsx files are allowed. Please try uploading again.'
                );
            }
        });
    };

    const checkExcelSheets = (file, callback) => {
        const reader = new FileReader();

        reader.onload = e => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            // Check the number of sheets in the workbook
            if (workbook.SheetNames.length === 1) {
                callback(true);
            } else {
                callback(false);
            }
        };

        reader.readAsArrayBuffer(file);
    };

    window.addEventListener(
        'drop',
        function callback(e) {
            e.preventDefault();
        },
        false
    );

    return (
        <>
            <Dropzone
                onDrop={acceptedFiles => handleExcelDrop(acceptedFiles, rest)}
            >
                {({ getRootProps, getInputProps }) => (
                    <div
                        {...getRootProps()}
                        className="c-upload-drag"
                        style={{ cursor: 'pointer' }}
                    >
                        <img src={uploadFileBtn} alt="File Upload" />
                        <div className="c-upload-browse">
                            <div className="c-upload-browse__info">
                                Drag and Drop Files or&nbsp;
                                <label className="c-upload-browse__link">
                                    <span>Browse</span>
                                </label>
                                <input {...getInputProps()} />
                            </div>
                            <span className="c-upload-browse__desc">
                                Max File Size: 200 KB | File Format: XLSX
                            </span>
                        </div>
                    </div>
                )}
            </Dropzone>
            <ErrorText errorMsg={errorMsg} />
        </>
    );
};

export default FileUpload;
