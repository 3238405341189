import React, { useEffect, useState } from 'react';

function MicroFrontend({
    name,
    host,
    history,
    useKeycloak,
    rootUrl,
    menuName,
    globalConfigData,
    selectedApp
}) {
    console.log(selectedApp , 'selecteeddddddddddddddddddddddddddd')
    useEffect(() => {
        const scriptId = `micro-frontend-script-${name}`;
        console.log({
            name,
            host,
            history,
            useKeycloak,
            rootUrl,
            menuName,
            globalConfigData,
            selectedApp
        } , 'micro-frontend')
        const renderMicroFrontend = () => {
            window[`render${name}`] &&
                window[`render${name}`](
                    `${name}-container`,
                    history,
                    useKeycloak,
                    rootUrl,
                    menuName,
                    globalConfigData,
                    selectedApp
                );
        };

        if (document.getElementById(scriptId)) {
            renderMicroFrontend();
            return;
        } else {
            fetch(`${host}/asset-manifest.json`)
                .then(res => {
                    if (res.status === 200) return res.json();
                    throw new Error(
                        `${host} -> host response status code is not 200!`
                    );
                })
                .then(manifest => {
                    const script = document.createElement('script');
                    script.id = scriptId;
                    script.crossOrigin = '';
                    let temp = host.split('/');
                    let mainJS = manifest.files['main.js'].replace(
                        `/${temp[temp.length - 1]}`,
                        ''
                    );
                    script.src = `${host}${mainJS}`;
                    script.onload = () => {
                        renderMicroFrontend();
                    };
                    document.head.appendChild(script);

                    const style = document.createElement('link');
                    style.id = scriptId+"-css";
                    style.rel = 'stylesheet';
                    style.type = 'text/css';
                    let tempPath = host.split('/');
                    let mainCSS = manifest.files['main.css']?.replace(
                        `/${tempPath[tempPath.length - 1]}`,
                        ''
                    );
                    style.href = `${host}${mainCSS}`;
                    style.media = 'all';
                    document.head.appendChild(style);
                })
                .catch(err =>
                    err?.name === 'TypeError' &&
                    err?.message === 'Failed to fetch'
                        ? console.error(
                              `${host} -> Failed to fetch! Host not available!`
                          )
                        : console.error(err)
                );
        }
        // return () => {
        //     window[`unmount${name}`] &&
        //         window[`unmount${name}`](`${name}-container`);
        // };
    }, []);

    return <main id={`${name}-container`} style={{
        display:"flex",
        width:"100%",
        flexDirection:"column"
    }} />;
}

MicroFrontend.defaultProps = {
    document,
    window
};

export default MicroFrontend;
