import React from 'react';

const BodyText = ({ text }) => {
    return (
        <tr>
            <td colSpan="100%" style={{ textAlign: 'center' }}>
                {text}
            </td>
        </tr>
    );
};

export default BodyText;
