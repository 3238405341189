import React from 'react';

import RadioBtn from './RadioBtn';

const RadioBtnGroup = ({
    radioBtnList,
    radioGroupName,
    selectedRadioBtn,
    setSelectedRadioBtn
}) => {
    return (
        <div className="c-upload__radio">
            {radioBtnList.map((item, index) => {
                return (
                    <RadioBtn
                        key={index}
                        item={item}
                        groupName={radioGroupName}
                        selectedRadioBtn={selectedRadioBtn}
                        setSelectedRadioBtn={setSelectedRadioBtn}
                    />
                );
            })}
        </div>
    );
};

export default RadioBtnGroup;
